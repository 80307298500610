import React from 'react';
import { Link as MuiLink } from '@mui/material';
import '../App.css';
import './Header.css';
import logoCabana from '../media/images/Cabana.svg';
import arrowBack from '../media/images/arrowBack.svg';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jwt from "jwt-decode";


interface HeaderProps {
    link?: string;
    textLink?: string;
    hideLanguageSelect?: boolean;
    languageLocale?: string | null;
    wishlistUrl?: string | null;
    hideLogout?: boolean;
    showWishlistLink?: boolean;
}

function Header({ link, textLink, hideLanguageSelect = true, languageLocale = null, wishlistUrl = null, hideLogout = false, showWishlistLink = false }: HeaderProps) {
    //console.log(sessionStorage.getItem('locale'), 'sessionStorage.getItem(locale)');

    const { t, i18n } = useTranslation();

    const token = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const user: any = token === null ? null : jwt(token);
    let logoStyle: any = {};
    if(wishlistUrl !== null) {
        logoStyle = {cursor: 'pointer'};
    } else {
        if(user !== null && user !== undefined && user.role !== 1 && user.role !== 2) {
            logoStyle = {cursor: 'pointer'};
        } else {
            logoStyle = {cursor: 'default'};
        }
    }

    if(user !== null && user !== undefined) {
        if(user.role !== 1) {
            hideLogout = true;
        }
    }

    const [language, setLanguage] = React.useState<string>('en');
    const navigate = useNavigate();


    const changeLanguage = (lng: any) => {
        setLanguage(lng);
        sessionStorage.setItem('locale', lng === 'en' ? '2' : '1');
        i18n.changeLanguage(lng);
        window.location.reload();
    };


    const handleImageClick = () => {
        if(wishlistUrl !== null) {
            navigate(wishlistUrl);
            logoStyle = {cursor: 'pointer'};
        } else {
            if(user !== null && user !== undefined && user.role !== 1 && user.role !== 2) {
                navigate(`/`);
            } else {
                logoStyle = {cursor: 'default'};
                return null;
            }
        }
    };

    //console.log(language, 'language HERE');
    //console.log(initialLanguage, 'initialLanguage HERE');
    //console.log(i18n.language, 'i18n.language HERE');
    const languageSelector = () => {
        if(hideLanguageSelect) return ('');
        return (
            <div className="LanguageContainer">
                <FormControl>
                    <Select
                        id="language-select"
                        value={i18n.language}
                        label="Language"
                        onChange={(event) => {
                            changeLanguage(event.target.value);
                        }}
                        sx={{
                            "& fieldset": {
                                border: "none",
                            },
                        }}
                    >
                        <MenuItem style={{fontSize: '13px', fontFamily: "Cormorant !important"}} value={'en'}>EN</MenuItem>
                        <MenuItem style={{fontSize: '13px', fontFamily: "Cormorant !important"}} value={'fr'}>FR</MenuItem>
                    </Select>
                </FormControl>
            </div>
        );
    }
    const logoutButton = (): JSX.Element | string => {
        if(token === null || token === undefined || hideLogout) {
            return '';
        } else {
            return (
                <span
                    className="LogOutLink"
                    onClick={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        window.location.href = `/login`;
                    }}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                >
                    {t('logOut')}
                </span>
            );
        }
    }

    const wishlistLink = (): JSX.Element | string => {
        if(showWishlistLink && wishlistUrl !== null) {
            return (
                <span
                    className="WishlistLink"
                    onClick={() => {
                        navigate(wishlistUrl);
                    }}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                >
                    My List
                </span>
            );
        } else {
            return '';
        }
    }

    return (
        <div >
            <div className="containerCabanaLogo">
                {link && (
                    <div className="containerLinkBack">
                        <MuiLink href={link} className="linkBack" rel="noopener noreferrer" underline="none">
                            <div className="containerArrowBack">
                                <img src={arrowBack} alt="arrow back" />
                            </div>
                            <span className="BackToText">{t('backTo')}</span>
                        </MuiLink>
                    </div>
                )}
                <img className="LogoCabana" style={logoStyle} onClick={() => {handleImageClick()}} src={logoCabana} alt="Logo Cabana"/>
                <div className="HeaderActions">
                    {wishlistLink()}
                    {logoutButton()}
                    {languageSelector()}
                </div>
            </div>
        </div>
    );
}

export default Header;
