import React, {useEffect, useState} from "react";
import Header from "../Header";
import dataProvider from "../../provider/api-interface";
import './Checkout.css';
import {Loader} from "../Loader";

import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Alert, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AddressElement } from '@stripe/react-stripe-js';


const stripeApiKey: any = process.env.REACT_APP_STRIPE_API_KEY;
const stripeSecret: any = process.env.REACT_APP_STRIPE_CLIENT_SECRET;
const stripePromise = loadStripe(stripeApiKey);



export default function Checkout() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [checkoutElement, setCheckoutElement] = useState<any>(<div></div>);

    const [loading, setLoading] = useState<boolean>(true);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState<any>('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [alertAutoClose, setAlertAutoClose] = useState(false);

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    useEffect(() => {
        renderCheckout();
    }, []);

    const gifterObject: any = localStorage.getItem('gifterObject');
    //console.log(gifterObject, 'gifterObject');
    if(gifterObject === null) {
        navigate('/gifter/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    let obj: any = JSON.parse(gifterObject);
    //console.log(obj, 'obj');
    const link = `/gifter/finalize-gift/${obj.wishlistProductId}?listCode=${obj.wishlistCode}`;
    const wishlistUrl = `/gifter/registry/${obj.wishlistCode}`;



    const CheckoutForm = (paymentIntentId: any) => {
        const stripe = useStripe();
        const elements = useElements();
        const [buttonDisabled, setButtonDisabled] = useState(false);

        const checkAvailability = (): any => {
            dataProvider.checkProductAvailability(obj.product.variation[0].sku).then((response: any) => {
                //console.log(response, 'product availability');
                if(response[0].hasOwnProperty('availability')) {
                    if(parseInt(response[0].availability) === 0) {
                        setAlertText("Product not available at the moment");
                        setAlertType("warning");
                        setAlertOpen(true);
                        autoClose();
                        setButtonDisabled(true);
                    }
                }
            });
        }

        const handleSubmit = async (event: any) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            const address = elements?.getElement(AddressElement);
            const addressResult = await address?.getValue();
            if (!addressResult?.complete) {
                console.log('Indirizzo non completo');
                setButtonDisabled(false);
                return;
            }

            setButtonDisabled(true);
            elements?.submit();

            const billingAddress = addressResult.value.address;
            const billingPhone = addressResult.value.phone;
            const billingName = addressResult.value.firstName;
            const billingSurname = addressResult.value.lastName;

            const paymentMethodData: any = {
                elements,
                params: {
                    billing_details: {
                        name: `${obj.name} ${obj.surname}`,
                        email: obj.email,
                        address: billingAddress, // Include l'indirizzo dal componente AddressElement
                    },
                }
            };
            await stripe?.createPaymentMethod(paymentMethodData).then((paymentMethod: any) => {
                    //console.log('qui no')
                    console.log(paymentMethod, 'paymentMethod');
                    const paymentMethodId = paymentMethod.paymentMethod.id;
                    console.log("PaymentMethodId:", paymentMethodId);
                    const paymentIntentForRequest = paymentIntentId.paymentIntentId.toString();

                    const dataConfirm = {
                        "wishlistProductId": obj.wishlistProductId,
                        "listCode": obj.wishlistCode,
                        "paymentIntentId": paymentIntentForRequest,
                        "paymentMethodId": paymentMethodId,
                        "gifter": {
                            "name": obj.name,
                            "surname": obj.surname,
                            "email": obj.email,
                            "message": obj.message
                        },
                        "billingName": billingName,
                        "billingSurname": billingSurname,
                        "billingPhone": billingPhone,
                        "billingAddress": billingAddress
                    }

                    dataProvider.confirmPayment(dataConfirm).then((response: any) => {
                        //console.log(response, 'response');
                        if(response !== null) {
                            setAlertText(t('messageCreateSuccess'));
                            setAlertOpen(true);
                            setAlertType("success");
                            setTimeout(() => {
                                navigate(`/gifter/purchase-success?listCode=${obj.wishlistCode}`);
                            }, 2000);
                        } else {
                            setAlertText(t('paymentError'));
                            setAlertOpen(true);
                            setAlertType("error");
                            setButtonDisabled(false);
                            autoClose();
                        }
                    }).catch((error: any) => {
                        console.log(error);
                        setAlertText(t('paymentError'));
                        setAlertOpen(true);
                        setAlertType("error");
                        setButtonDisabled(false);
                        autoClose();
                    });

                    setLoading(false);

                }).catch((error: any) => {
                    console.log(error);
                    setAlertText(t('paymentError'));
                    setAlertOpen(true);
                    setAlertType("error");
                    autoClose();
                    setLoading(false);
                    setButtonDisabled(false);
                });
        };

        const paymentElementOptions: any = {
            layout: 'tabs',
            paymentMethodCreation: 'manual',
            paymentMethodTypes : ['card']
        }

        const addressOptions: any = {
            mode: "billing",
            fields: {
                phone: "always",
            },
            validation: {
                phone: {required: "always"}
            },
            display: {
                name: 'split'
            }
        };

        let price: any = parseFloat(obj.product.variation[0].price_value);
        price = price.toFixed(2);
        price = price.toString();
        price = price.replace('.', ',');


        return (
            <form onSubmit={handleSubmit}>
                <h5 className="AddressTitle">Billing details</h5>
                <AddressElement  options={addressOptions} />
                <h5 className="AddressTitle mt-4">Payment details</h5>
                <PaymentElement options={paymentElementOptions}/>
                <div className="ButtonContainer CheckoutPay">
                    <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-2">
                        <div className="d-flex flex-column w-50">
                            <span className="PriceLabel">Total</span>
                            <span
                                className="PriceValue">{obj.product.variation[0].price_currency === 'EUR' ? '€' : obj.product.variation[0].price_currency}{price}</span>
                        </div>
                        <button className="Fgift-button w-50" disabled={!stripe || buttonDisabled}>PAY</button>
                    </div>
                    <span className="PriceDescription">
                        This is the final step, after you touching Pay Now button, the payment will be transaction all info
                    </span>
                </div>
            </form>
        )
    };


    const data = {
        "listCode": obj.wishlistCode,
        "wishlistProductId": obj.wishlistProductId
    }

    const renderCheckout = async () => {
        let checkoutElement = <div></div>;
        setLoading(true);
        await dataProvider.createPayment(data).then((response: any) => {
            //console.log(response, 'response HERE');
            const options: any = {
                // passing the client secret obtained from the server
                paymentMethodCreation: 'manual',
                clientSecret: response.clientSecret,
                appearance: {
                    labels: "floating",
                    variables: {
                        colorPrimary: "#a3a3a3",
                        colorText: "#000000",
                        colorDanger: "#000000",
                        fontFamily: "PT Sans",
                        spacingUnit: "5px",
                        borderColor: "#342",
                        borderRadius: "0px",
                        backgroundColor: "transparent",
                    },
                }
            };

            setLoading(false);

            checkoutElement = (
                <div>
                    <div className="CheckoutFormContainer">
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm paymentIntentId={response.intentId} />
                        </Elements>
                    </div>
                </div>
            );
            setCheckoutElement(checkoutElement);
        }).catch((error: any) => {
            console.log(error, 'error');
            checkoutElement = (
                <div>
                    <div className="CheckoutFormContainer">
                        <h1>Errore durante il pagamento</h1>
                    </div>
                </div>
            );
            setCheckoutElement(checkoutElement);
        });
    }

    return (
        <div>
            <Header link={link} hideLogout={true} wishlistUrl={wishlistUrl} showWishlistLink={true}/>
            <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                <Alert severity={alertType}
                       onClose={() => setAlertOpen(false)}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setAlertOpen(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit"/>
                           </IconButton>
                       }
                >
                    {alertText}
                </Alert>
            </Collapse>
            <div className="CheckoutContainer">
                <div className="row">
                    <div className="col-3 d-md-flex d-none">

                    </div>
                    <div className="col-12 col-md-6 px-md-0 px-5">
                        <div className="CheckoutTitle my-4">
                            <span><strong
                                style={{
                                    fontFamily: 'Atacama VAR',
                                    color: '#33383C !important',
                                    fontSize: '16px',
                                    fontWeight: '400'
                                }}>CHECKOUT</strong>
                            </span>
                        </div>
                        {loading ?
                            <div style={{height: '50vh', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <Loader/></div>
                            : ''}
                        {checkoutElement}
                    </div>
                    <div className="col-3 d-md-flex d-none">

                    </div>
                </div>

            </div>
        </div>
    );
};