import React, {useState, useEffect, useRef} from 'react';
//import { login } from '../../services/authService';
import jwt from 'jwt-decode';

import { useLocation, useNavigate } from "react-router";
import {useSearchParams} from "react-router-dom";

import {rolesCodes, mainRolesRoutes, apiBaseURL, isJwtExpired} from "../../global";

import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    Button,
    TextField,
    Modal,
    Typography,
    Alert,
    AlertTitle,
    Collapse,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    InputAdornment, Checkbox, useMediaQuery, Divider
} from '@mui/material';
import Header from '../Header';
import './style.css';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Link} from "react-router-dom";
import {validateEmail, validatePassword} from "../../services/CheckUtils";
import dataProvider from "../../provider/api-interface";
import {changeLanguage} from "i18next";

import Background from "../../media/images/LoginBackground.jpg";
import FooterCabana from "../pages/FooterCabana";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import './LoginSignup.css';
import * as EmailValidator from "email-validator";

import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import FacebookLogin from '@greatsumini/react-facebook-login';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import FacebookButtonIcon from '../../media/images/FacebookButtonIcon.svg';
import GoogleIcon from '../../media/images/GoogleIcon.svg';




import ReCAPTCHA from "react-google-recaptcha";
function LoginSignup() {
    const isDesktop = useMediaQuery('(min-width:992px)'); // lg breakpoint

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    /*const [role, setRole] = useState<string>(props.role);
    const [routeRedirect, setRouteRedirect] = useState<string>(props.routeRedirect);*/

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [recaptchaRef, setRecaptchaRef] = useState<any>(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentials'));

    const location = useLocation();

    //SIGNUP
    const [signupPassword, setSignupPassword] = useState<string>("");
    const [isSignupPasswordValid, setIsSignupPasswordValid] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [showSignupPassword, setShowSignupPassword] = useState(false);
    const [termsStyle, setTermsStyle] = useState<any>({fontWeight: "400"});
    const [privacyStyle, setPrivacyStyle] = useState<any>({fontWeight: "400"});
    const [marketingAccepted, setMarketingAccepted] = useState<boolean>(false);
    const [marketingStyle, setMarketingStyle] = useState<any>({fontWeight: "400"});
    const handleClickShowSignupPassword = () => setShowSignupPassword(!showSignupPassword);
    const [signupEmail, setSignupEmail] = useState<string>("");

    const [isSignupEmailValid, setIsSignupEmailValid] = useState(true);


    const [defaultCountry, setDefaultCountry] = useState<any>('it_it');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    let link = 'https://www.dior.com/fr_fr/fashion/enfant/liste-de-naissance-baby-dior'

    const googleClientID: any = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const facebookAppId: any = process.env.REACT_APP_FACEBOOK_APP_ID;
    const [facebookAccessToken, setFacebookAccessToken] = useState<any>(null);

    FacebookLoginClient.init({ appId: facebookAppId, version: 'v16.0' });

    const handleGoogleRegistration =  async (accessToken: any) => {
        var registration_token = accessToken;
        let data = {
            registration_token: registration_token,
            acceptance_privacy_and_terms: 1,
            acceptance_condition_settings: 1,
            accept_marketing_consents: marketingAccepted ? 1 : 0,
        }

       await dataProvider.googleAuthRegistration(data).then((response: any) => {
            if(response !== null) {
                if(response.status === 200) {
                    console.log(response, 'response');
                    const userId: string = response.data.userId;
                    const encodedUserId = btoa(userId);

                    navigate(`/get-started/${encodedUserId}`);
                } else {
                    setAlertText(t('registrationError'));
                    if(response.status === 409) {
                        setAlertText(t('emailAlreadyExists'));
                    }
                    setAlertOpen(true);
                    setAlertType("error");
                    setButtonDisabled(false);
                    autoClose();
                }
            }
        }).catch((error: any) => {
            setAlertText(t('registrationError'));
            if(error.status === 409) {
                setAlertText(t('emailAlreadyExists'));
            }
            setAlertOpen(true);
            setAlertType("error");
            setButtonDisabled(false);
            autoClose();
        });

    }

    const handleGoogleLogin = async (token: any) => {
        var login_token = token;
        const data = {
            login_token: login_token
        }
        let response: any = await dataProvider.googleAuthLogin(data);

        if(!response || response.data === null) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("wrongCredentialsParagraph"));
            return;
        }

        if(response.hasOwnProperty('userStatus') && parseInt(response.userStatus) === 0) { //if user registration is not complete
            const userId: string = response.userId;
            const encodedUserId = btoa(userId);

            navigate(`/get-started/${encodedUserId}`);
        } else {
            //console.log(response);
            const token: string = response.token;
            //console.log(token); return;
            localStorage.setItem('token', token);

            const redirectURL = searchParams.get('redirectURL');
            if (redirectURL) {
                window.location.href = redirectURL;
            } else {
                let user: any = jwt(token);
                let roleCode: number = parseInt(user.role);
                let role = rolesCodes[roleCode];

                const language = 'en_gb';
                setLanguage(language);
                dataProvider.getWishlistByGifteeId(user.id, token).then((wishlist: any) => {
                    if (wishlist !== null && wishlist.data !== null) {
                        //console.log(wishlist, 'wishlist')
                        //console.log(`/${mainRolesRoutes[role]}/registry/${wishlist[0].id}`)
                        navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                    } else {
                        //console.log('redirect to login success ok')
                        //return;
                        navigate(`/${mainRolesRoutes[role]}/login-success`);
                    }
                });
            }
        }
    }

    const handleFacebookRegistration =  async (facebookAccessToken: any) => {
        var registration_token = facebookAccessToken;
        if(facebookAccessToken !== null) {
            let data = {
                registration_token: registration_token,
                acceptance_privacy_and_terms: 1,
                acceptance_condition_settings: 1,
                accept_marketing_consents: marketingAccepted ? 1 : 0,
            }

            await dataProvider.facebookAuthRegistration(data).then((response: any) => {
                if (response !== null) {
                    if (response.status === 200) {
                        console.log(response, 'response');
                        const userId: string = response.data.userId;
                        const encodedUserId = btoa(userId);

                        navigate(`/get-started/${encodedUserId}`);
                    } else {
                        setAlertText(t('registrationError'));
                        if (response.status === 409) {
                            setAlertText(t('emailAlreadyExists'));
                        }
                        setAlertOpen(true);
                        setAlertType("error");
                        setButtonDisabled(false);
                        autoClose();
                    }
                }
            }).catch((error: any) => {
                setAlertText(t('registrationError'));
                if (error.status === 409) {
                    setAlertText(t('emailAlreadyExists'));
                }
                setAlertOpen(true);
                setAlertType("error");
                setButtonDisabled(false);
                autoClose();
            });
        }

    }

    const handleFacebookLogin = async (token: any) => {
        var login_token = token;
        if(login_token !== null) {
            const data = {
                login_token: login_token
            }
            let response: any = await dataProvider.facebookAuthLogin(data);

            if(response === null || response?.data === null) {
                console.log(response, 'HERE 1');
                setAlertOpen(true);
                setAlertType("error");
                setAlertText(t("wrongCredentialsParagraph"));
                return;
            }

            if(response.hasOwnProperty('userStatus') && parseInt(response.userStatus) === 0) { //if user registration is not complete
                const userId: string = response.userId;
                const encodedUserId = btoa(userId);

                navigate(`/get-started/${encodedUserId}`);
            } else {
                console.log(response, 'HERE 2');
                const token: string = response.token;
                //console.log(token); return;
                localStorage.setItem('token', token);

                const redirectURL = searchParams.get('redirectURL');
                if (redirectURL) {
                    window.location.href = redirectURL;
                } else {
                    let user: any = jwt(token);
                    let roleCode: number = parseInt(user.role);
                    let role = rolesCodes[roleCode];

                    if (roleCode === 1) {
                        const language = 'en_gb';
                        setLanguage(language);
                        dataProvider.getWishlistByGifteeId(user.id, token).then((wishlist: any) => {
                            if (wishlist !== null && wishlist.data !== null) {
                                //console.log(wishlist, 'wishlist')
                                //console.log(`/${mainRolesRoutes[role]}/registry/${wishlist[0].id}`)
                                navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                            } else {
                                //console.log('redirect to login success ok')
                                //return;
                                navigate(`/${mainRolesRoutes[role]}/login-success`);
                            }
                        });

                    }

                    if (roleCode === 2) {
                        let wishlistId = searchParams.get('id');
                        if (wishlistId !== null) {
                            navigate(`/${mainRolesRoutes[role]}/registry/${wishlistId}`);
                        } else {
                            navigate(`/${mainRolesRoutes[role]}/login-success`);
                        }
                    }

                }
            }
        } else {
            setAlertText(t('loginError'));
            setAlertOpen(true);
            setAlertType("error");
            setButtonDisabled(false);
            autoClose();
        }

    }


    link = `https://cabanamagazine.com/collections`;


    useEffect(() => {
        let tempReCaptchaRef: any = React.createRef();
        setRecaptchaRef(tempReCaptchaRef);
    }, []);



    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    if(searchParams.get('confirmToken') !== null) {
        const token: any = searchParams.get('confirmToken');
        dataProvider.confirmUnifiedRegistration(token).then((response: any) => {
            if(response !== null) {
                setAlertOpen(true);
                setAlertType("success");
                setAlertText(t("registrationSuccess"));
                autoClose();
            }
        });
    }

    let jwtToken: any = null;

    if(localStorage.getItem('token') !== null){
        jwtToken = localStorage.getItem('token');
    } else if(sessionStorage.getItem('token') !== null) {
        jwtToken = sessionStorage.getItem('token');
    }

    /*if (
        jwtToken !== null
    ) {
        //console.log(jwtToken);
        if (!isJwtExpired(jwtToken)) {
            let user: any = jwt(jwtToken!);
            let roleCode: number = parseInt(user.role);
            let role = rolesCodes[roleCode];
            if(roleCode == 1 || roleCode == 2) {
                navigate(`/${mainRolesRoutes[role]}/`);
            } else if(roleCode == 0) {
                navigate(`/${mainRolesRoutes[role]}/login-success`);
            } else {
                sessionStorage.clear();
                localStorage.clear();
                window.location.reload();
                // navigate(`/${mainRolesRoutes[role]}/login-success`);
            }
        }
    }*/

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    }

    const handleSignupEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignupEmail(event.target.value);
        setIsSignupEmailValid(validateSignupEmail(event.target.value));
    };

    const validateSignupEmail = (email: string) => {
        return EmailValidator.validate(email);
    };

    const handleSignupPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSignupPassword(event.target.value);
        setIsSignupPasswordValid(validatePassword(event.target.value));
    };

    const handleTermsAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setTermsStyle({fontWeight: "700"});
            setTermsAccepted(true);
        } else {
            setTermsStyle({fontWeight: "400"});
            setTermsAccepted(false);
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleLoginFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let response: any = await dataProvider.login(email, password);

        if(!response) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("wrongCredentialsParagraph"));
            return;
        }

        if(response.hasOwnProperty('userStatus') && parseInt(response.userStatus) === 0) { //if user registration is not complete
            const userId: string = response.userId;
            const encodedUserId = btoa(userId);

            navigate(`/get-started/${encodedUserId}`);
        } else {
            //console.log(response);
            const token: string = response.token;
            //console.log(token); return;
            localStorage.setItem('token', token);

            const redirectURL = searchParams.get('redirectURL');
            if( redirectURL ) {
                window.location.href = redirectURL;
            }
            else {
                let user: any = jwt(token);
                let roleCode: number = parseInt(user.role);
                let role = rolesCodes[roleCode];
                if (roleCode === 1) {
                    const language = 'en_gb';
                    setLanguage(language);
                    dataProvider.getWishlistByGifteeId(user.id, token).then((wishlist: any) => {
                        if (wishlist !== null && wishlist.data !== null) {
                            //console.log(wishlist, 'wishlist')
                            //console.log(`/${mainRolesRoutes[role]}/registry/${wishlist[0].id}`)
                            navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                        } else {
                            //console.log('redirect to login success ok')
                            //return;
                            navigate(`/${mainRolesRoutes[role]}/login-success`);
                        }
                    });

                }

                if (roleCode === 2) {
                    let wishlistId = searchParams.get('id');
                    if (wishlistId !== null) {
                        navigate(`/${mainRolesRoutes[role]}/registry/${wishlistId}`);
                    } else {
                        navigate(`/${mainRolesRoutes[role]}/login-success`);
                    }
                }

            }
        }
    };

     // Create a reference for the form
    // Function to handle submit when clicking the external button
    const signupFormRef = useRef<HTMLFormElement>(null);
    const handleSignupExternalSubmit = () => {
        if (signupFormRef.current) {
            signupFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    const handleSignupFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setButtonDisabled(true);
        recaptchaRef.current.execute();
        let recaptchaToken = recaptchaRef.current.getValue();
        //console.log(recaptchaToken, 'recaptchaToken'); return;
        //LOGIN INFO
        if (signupEmail === '' || signupPassword === '') {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("emptyFieldsParagraph"));
            autoClose();
            setButtonDisabled(false);
            return;
        }

        if (!isSignupPasswordValid) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("invalidPasswordParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }

        //VALIDATE MAIL AND PHONE
        if(!validateSignupEmail(signupEmail)) {
            setAlertText(t('mailNotValid'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if (!termsAccepted) {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t("termsNotAcceptedParagraph"));
            setButtonDisabled(false);
            autoClose();
            return;
        }

        let registrationObject = {
            email: signupEmail,
            password: signupPassword,
            acceptance_privacy_and_terms: 1,
            acceptance_condition_settings: 1,
            accept_marketing_consents: marketingAccepted ? 1 : 0,
            recaptcha_token: recaptchaToken
        }

        console.log(registrationObject, 'record');

        await dataProvider.unifiedRegistrationStep1(registrationObject).then((response: any) => {
            if(response !== null) {
                if(response.status === 200) {
                    console.log(response, 'response');
                    const userId: string = response.data.userId;
                    const encodedUserId = btoa(userId);

                    navigate(`/get-started/${encodedUserId}`);
                } else {
                    setAlertText(t('registrationError'));
                    if(response.status === 409) {
                        setAlertText(t('emailAlreadyExists'));
                    }
                    setAlertOpen(true);
                    setAlertType("error");
                    setButtonDisabled(false);
                    autoClose();
                }
            }
        });

    };

    const handleMarketingAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked){
            setMarketingStyle({fontWeight: "700"});
            setMarketingAccepted(true)
        } else {
            setMarketingStyle({fontWeight: "400"});
            setMarketingAccepted(false)
        }
    }

    const GoogleLoginCustom: any = () => {
        const testLogin = useGoogleLogin({
            onSuccess: tokenResponse => handleGoogleLogin(tokenResponse.access_token),
        })
        return <button className="Fgift-button Fgift-button-rounded Fgift-button-reverted Google-button col-6 col-lg-12" onClick={() => testLogin()}><span className="fb-text"><img src={GoogleIcon}/><span className="d-none d-lg-block">{t('googleLogin')}</span></span></button>
    }

    const GoogleRegisterCustom: any = () => {
        const testLogin = useGoogleLogin({
            onSuccess: tokenResponse => handleGoogleRegistration(tokenResponse.access_token),
        })
        return <button className="Fgift-button Fgift-button-rounded Fgift-button-reverted Google-button col-6" onClick={() => testLogin()}><span className="fb-text"><img src={GoogleIcon}/><span className="d-none d-lg-block">{t('googleLogin')}</span></span></button>
    }

    const CustomDivider = () => {
        return   <div className="CustomDividerContainer d-flex justify-content-center text-center mt-0 mb-4" style={{textTransform: 'uppercase'}}>{t('orContinueWith')}</div>
    }

    return (
        <div>
            <Header hideLanguageSelect={true}
                    /*link={link}
                    textLink={t('backTo')}*/
                    languageLocale={'en'}
                    hideLogout={true}
            />
            <div className="row no-gutter LoginSignupContainer">
                <div className="col-12 col-lg-6 PublicRegistrationTitle" style={{
                    minHeight: '21vh',
                    height: 'calc(100vh - 60px)',
                    background: `url(${Background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '50% 48%',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <h3 className="d-block d-lg-none text-uppercase">{t('loginSignupPage.loginTitle')}</h3>
                </div>
                <div
                    className="col-12 col-lg-6 d-flex FormsContainer flex-column align-items-center justify-content-start px-0 px-lg-3">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert severity={alertType}
                               onClose={() => setAlertOpen(false)}
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setAlertOpen(false);
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit"/>
                                   </IconButton>
                               }
                        >
                            {alertText}
                        </Alert>
                    </Collapse>
                    <div className="LoginRow row w-100 mt-3 mb-2 justify-content-lg-between">
                        <div
                            className="col-12 py-4 col-lg-3 d-none d-lg-flex flex-column justify-content-lg-start align-items-lg-start LoginSignupAccordionSummary">
                            <h3>{t('loginSignupPage.login')}</h3>
                            <p>{t('loginSignupPage.loginSubtitle')}</p>
                        </div>
                        <div className="col-12 col-lg-8 d-none d-lg-block">
                            {isDesktop ? <form className="LoginForm p-0 mt-5" onSubmit={handleLoginFormSubmit}>
                                    <div className="FormContainer">
                                        <TextField
                                            label={t("emailLabel")}
                                            value={email}
                                            onChange={handleEmailChange}
                                            error={!isEmailValid}
                                            helperText={!isEmailValid && t("notValidMailHelper")}
                                            variant="outlined"
                                        />
                                        <TextField
                                            type={showPassword ? "text" : "password"}
                                            label={t("password")}
                                            value={password}
                                            onChange={handlePasswordChange}
                                            variant="outlined"
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOffIcon/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <Link to={`/reset-password`} className="ResetPasswordLink">
                                            {t("loginSignupPage.forgotPassword")}
                                        </Link>
                                    </div>
                                    <div className="ButtonContainer" style={{position: 'relative'}}>
                                        <button type="submit" className="Fgift-button Fgift-button-reverted">
                                            {t("loginButton")}
                                        </button>
                                    </div>
                                </form>
                                : ''}
                            {/*<CustomDivider/>
                            <div
                                className="d-none d-lg-flex flex-row gap-3 justify-content-center align-items-center mb-5 mt-3">
                                <FacebookLogin
                                    children={<div className="fb-text"><img
                                        src={FacebookButtonIcon}/><span>{t('facebookLogin')}</span></div>}
                                    className="Fgift-button Fgift-button-rounded FB-Desktop Fgift-button-reverted Facebook-access mb-2"
                                    appId={facebookAppId}
                                    onSuccess={(response) => {
                                        console.log(response, 'response')
                                        handleFacebookLogin(response.accessToken)
                                    }}
                                    onFail={(error) => {
                                        setAlertText(t('registrationError'));
                                        setAlertOpen(true);
                                        setAlertType("error");
                                        setButtonDisabled(false);
                                        autoClose();
                                    }}
                                />
                                <GoogleOAuthProvider clientId={googleClientID}>
                                    <GoogleLoginCustom/>
                                </GoogleOAuthProvider>
                            </div>*/}
                        </div>
                        <div className="col-12 d-block d-lg-none no-gutters">
                            <h4 className="mb-4 mt-2">LOGIN</h4>
                            {/*<div
                                className="d-flex d-lg-none justify-content-center gap-3 flex-row align-items-center mb-4">
                                <FacebookLogin
                                    children={<div className="fb-text"><img src={FacebookButtonIcon}/><span
                                        className="d-none d-lg-block">{t('facebookLogin')}</span></div>}
                                    className="Fgift-button Fgift-button-rounded Fgift-button-reverted Facebook-access col-6 col-lg-12"
                                    appId={facebookAppId}
                                    onSuccess={(response) => {
                                        handleFacebookLogin(response.accessToken)
                                    }}
                                    onFail={(error) => {
                                        setAlertText(t('registrationError'));
                                        setAlertOpen(true);
                                        setAlertType("error");
                                        setButtonDisabled(false);
                                        autoClose();
                                    }}
                                />
                                <GoogleOAuthProvider clientId={googleClientID}>
                                    <GoogleLoginCustom />
                                </GoogleOAuthProvider>
                            </div>
                            <CustomDivider />*/}
                            {!isDesktop ? <form className="LoginForm p-0" onSubmit={handleLoginFormSubmit}>
                                    <div className="FormContainer">
                                        <TextField
                                            label={t("emailLabel")}
                                            value={email}
                                            onChange={handleEmailChange}
                                            error={!isEmailValid}
                                            helperText={!isEmailValid && t("notValidMailHelper")}
                                            variant="outlined"
                                        />
                                        <TextField
                                            type={showPassword ? "text" : "password"}
                                            label={t("password")}
                                            value={password}
                                            onChange={handlePasswordChange}
                                            variant="outlined"
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                        >
                                                            {showPassword ? <Visibility/> : <VisibilityOffIcon/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <Link to={`/reset-password`} className="ResetPasswordLink">
                                            {t("forgotPassword")}
                                        </Link>
                                    </div>
                                    <div className="ButtonContainer" style={{position: 'relative'}}>
                                        <button type="submit" className="Fgift-button Fgift-button-reverted">
                                            {t("loginButton")}
                                        </button>
                                    </div>
                                </form>
                                : ''}
                        </div>
                        <div className="col-12 col-lg-2 d-none d-lg-block"></div>
                    </div>
                    <Divider sx={{borderColor: "#cccccc",width: "105%"}} component="div" />
                    <div className="SignupRow row w-100 mt-3 mb-5 justify-content-lg-between">
                        <div
                            className="col-12 col-lg-3 py-4 d-none d-lg-flex flex-column justify-content-lg-start align-items-lg-start LoginSignupAccordionSummary">
                            <h3>{t('loginSignupPage.signup')}</h3>
                            <p>{t('loginSignupPage.signupSubtitle')}</p>
                        </div>
                        <div className="col-12 col-lg-8 d-none d-lg-block">
                            {isDesktop ? <form ref={signupFormRef} className="LoginForm p-0 mt-5 mb-4" onSubmit={handleSignupFormSubmit}>
                                <div className="FormContainer">
                                    <TextField
                                        label={t("emailLabel")}
                                        value={signupEmail}
                                        onChange={handleSignupEmailChange}
                                        required={true}
                                        error={!isSignupEmailValid}
                                        helperText={!isSignupEmailValid && t("notValidMailHelper")}
                                        variant="outlined"
                                    />
                                    <TextField
                                        type={showSignupPassword ? "text" : "password"}
                                        label={t('passwordLabel')}
                                        value={signupPassword}
                                        onChange={handleSignupPasswordChange}
                                        helperText={t("loginSignupPage.passwordHelper")}
                                        variant="outlined"
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowSignupPassword}
                                                    >
                                                        {showSignupPassword ? <Visibility/> :
                                                            <VisibilityOffIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                                    onChange={(token: any) => {
                                        console.log(token);
                                    }}
                                />
                            </form> : ''}
                            {/*<CustomDivider/>
                            <div
                                className="d-none d-lg-flex justify-content-center flex-row gap-3 align-items-center mb-5 mt-3">
                                <FacebookLogin
                                    children={<div className="fb-text"><img src={FacebookButtonIcon}/><span
                                        className="d-none d-lg-block">{t('facebookSignup')}</span></div>}
                                    className="Fgift-button Fgift-button-rounded Fgift-button-reverted Facebook-access FB-Desktop col-6 mb-2"
                                    appId={facebookAppId}
                                    onSuccess={(response) => {
                                        handleFacebookRegistration(response.accessToken)
                                    }}
                                    onFail={(error) => {
                                        setAlertText(t('registrationError'));
                                        setAlertOpen(true);
                                        setAlertType("error");
                                        setButtonDisabled(false);
                                        autoClose();
                                    }}
                                />
                                <GoogleOAuthProvider clientId={googleClientID}>
                                    <GoogleRegisterCustom/>
                                </GoogleOAuthProvider>
                            </div>*/}
                            {isDesktop ?
                                <div>
                                    <div className="mt-2 AcceptTermsContainer">
                                        <Checkbox
                                            onChange={handleTermsAccepted}
                                            color="primary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                            }}
                                        /> <span
                                        style={termsStyle}>{t('acceptTermsAndConditionsText')}
                                        <a
                                            style={termsStyle}
                                            href="https://cabanamagazine.com/pages/terms-and-conditions"
                                            target="_blank">{t('termsAndConditions')}</a> & <a
                                            style={termsStyle}
                                            href="https://cabanamagazine.com/pages/privacy-policy"
                                            target="_blank">{t('privacyPolicy')}</a></span>
                                    </div>
                                    <div className="mb-5 AcceptMarketingContainer">
                                        <Checkbox
                                            onChange={handleMarketingAccepted}
                                            color="primary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                            }}
                                        /> <span
                                        style={marketingStyle}>{t('loginSignupPage.marketingText')}</span>
                                    </div>
                                    <div className="ButtonContainer" style={{position: 'relative'}}>
                                        <button disabled={buttonDisabled} id="signup-confirm-button"
                                                className="Fgift-button" onClick={() => {
                                            handleSignupExternalSubmit()
                                        }}>
                                            {t('loginSignupPage.CTA')}
                                        </button>
                                    </div>
                                </div>
                        : ''}
                    </div>
                    <div className="col-12 d-block d-lg-none no-gutters">
                        <h4 className="mb-4">SIGN UP</h4>
                        {/*<div
                            className="d-flex d-lg-none justify-content-center flex-row gap-3 align-items-center mb-4">
                            <FacebookLogin
                                children={<div className="fb-text"><img src={FacebookButtonIcon}/><span
                                    className="d-none d-lg-block">{t('facebookSignup')}</span></div>}
                                className="Fgift-button Fgift-button-rounded Fgift-button-reverted Facebook-access col-6"
                                appId={facebookAppId}
                                onSuccess={(response) => {
                                    handleFacebookRegistration(response.accessToken)
                                }}
                                onFail={(error) => {
                                    setAlertText(t('registrationError'));
                                    setAlertOpen(true);
                                    setAlertType("error");
                                    setButtonDisabled(false);
                                    autoClose();
                                }}
                            />
                            <GoogleOAuthProvider clientId={googleClientID}>
                                <GoogleRegisterCustom/>
                            </GoogleOAuthProvider>
                        </div>
                        <CustomDivider/>*/}
                        {!isDesktop ? <form className="LoginForm p-0" onSubmit={handleSignupFormSubmit}>
                            <div className="FormContainer">
                                    <TextField
                                        label={t("emailLabel")}
                                        value={signupEmail}
                                        onChange={handleSignupEmailChange}
                                        required={true}
                                        error={!isSignupEmailValid}
                                        helperText={!isSignupEmailValid && t("notValidMailHelper")}
                                        variant="outlined"
                                    />
                                    <TextField
                                        type={showSignupPassword ? "text" : "password"}
                                        label={t('passwordLabel')}
                                        value={signupPassword}
                                        onChange={handleSignupPasswordChange}
                                        helperText={t("loginSignupPage.passwordHelper")}
                                        variant="outlined"
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowSignupPassword}
                                                    >
                                                        {showSignupPassword ? <Visibility/> :
                                                            <VisibilityOffIcon/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <div className="AcceptTermsContainer">
                                        <Checkbox
                                            onChange={handleTermsAccepted}
                                            color="primary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                            }}
                                        /> <span
                                        style={termsStyle}>{t('acceptTermsAndConditionsText')}
                                        <a
                                            style={termsStyle}
                                            href="https://cabanamagazine.com/pages/terms-and-conditions"
                                            target="_blank">{t('termsAndConditions')}</a> & <a
                                            style={termsStyle}
                                            href="https://cabanamagazine.com/pages/privacy-policy"
                                            target="_blank">{t('privacyPolicy')}</a></span>
                                    </div>
                                    <div className="AcceptMarketingContainer">
                                        <Checkbox
                                            onChange={handleMarketingAccepted}
                                            color="primary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                            sx={{
                                                color: 'black',
                                                '&.Mui-checked': {
                                                    color: 'black',
                                                },
                                            }}
                                        /> <span
                                        style={marketingStyle}>{t('loginSignupPage.marketingText')}</span>
                                    </div>
                                </div>
                                <div className="ButtonContainer" style={{position: 'relative'}}>
                                    <button disabled={buttonDisabled} id="signup-confirm-button" type="submit"
                                            className="Fgift-button">
                                        {t('loginSignupPage.CTA')}
                                    </button>
                                </div>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                                    onChange={(token: any) => {
                                        console.log(token);
                                    }}
                                />
                            </form> : ''}
                        </div>
                        <div className="col-12 col-lg-2 d-none d-lg-block"></div>
                    </div>
                </div>
            </div>
            {!isDesktop ? <FooterCabana showDisclaimer={false}/> : ''}
        </div>
    );
}

export default withTranslation()(LoginSignup);
