import React, {useEffect} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {Link, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router";

import './FooterCabana.css';

interface FooterProps {
    showDisclaimer?: boolean;
    locale?: string;
}


function FooterCabana({showDisclaimer = false, locale= 'fr_fr'}: FooterProps) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let disclaimerClass = 'col-12 FooterDisclaimer d-inline-flex';
    if(!showDisclaimer) {
        disclaimerClass = 'col-12 FooterDisclaimer d-none';
    }

    return (
        <div>
            <div className="row">
                <div className={disclaimerClass}>
                    <p>{t('footerCabana.disclaimer')}</p>
                </div>
            </div>
            <div className="FooterCabana row">
                <div className="FooterSection col-12 col-md-4">
                    <h4>INFORMATION</h4>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/pages/about-us?utm_source=wedding_registry`}>About Cabana</Link>
                    <Link target={'_blank'} to={`https://pin.it/6JR04AWY9`}>Cabana Events Inspirations</Link>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/collections?utm_source=wedding_registry`}>Shop Homeware on Cabana</Link>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/collections/gift-guide?utm_source=wedding_registry`}>Gift Guide</Link>
                </div>
                <div className="FooterSection col-12 col-md-4 mt-3 mt-md-0">
                    <div style={{height: '20px'}} className="d-none d-md-block"></div>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/pages/contact-us?utm_source=wedding_registry`}>Contact Us</Link>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/pages/privacy-policy?utm_source=wedding_registry`}>Privacy Policy</Link>
                    <Link target={'_blank'} to={`https://cabanamagazine.com/pages/cabana-store-location?utm_source=wedding_registry`}>Visit Our Store</Link>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(FooterCabana)
