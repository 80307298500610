import React, { useEffect, useState } from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import headerBackground from '../../media/images/GiftlistHeader.png';
import CabanaLogo from '../../media/images/Cabana.svg';

import SuccessImage from '../../media/images/PurchaseSuccessImage.png';

import {useNavigate} from "react-router";

import Header from '../Header';

import {mainRolesRoutes, checkPathName, rolesCodes} from "../../global";
import jwt from "jwt-decode";

import { useTranslation, withTranslation } from 'react-i18next';

import './PurchaseMessage.css';
import dataProvider from "../../provider/api-interface";

function PurchaseMessage() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [searchParams, setSearchParams] = useSearchParams();
    const wishlistCode = searchParams.get('listCode');


    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere
    console.log(user, 'user');
    const gifterObject = localStorage.getItem('gifterObject');
    if(gifterObject !== null) {
     localStorage.removeItem('gifterObject');
    }

    return (
        <div className="PurchaseMessage">
            <Header hideLogout={true} showWishlistLink={true} wishlistUrl={`/gifter/registry/${wishlistCode}`} />
            <div>
                <div className="row no-gutter">
                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center mt-2 mt-md-0">
                        <div style={{backgroundImage: `url(${SuccessImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} className="PurchaseImageContainer">
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="row PurchaseInfosContainer">
                            <div className="col-12 mt-2 d-flex flex-column">
                                <h1>{t('purchaseMessageTitle')}</h1>
                                <h3>{t('purchaseMessageSubtitle')}</h3>
                                <p>{t('purchaseMessageParagraph')}</p>
                                <div className="ButtonContainer" style={{width: 'calc(100% - 40px)'}}>
                                    <Link className="Fgift-button" style={{width: '100%'}}
                                          to={`/${pathName}/registry/${wishlistCode}`}
                                    >{t('purchaseMessageButton')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(PurchaseMessage);