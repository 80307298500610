import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {Accordion, AccordionDetails, AccordionSummary, InputAdornment} from "@mui/material";
import {Modal} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";

import jwt from 'jwt-decode';

import dataProvider from "../../provider/api-interface";

import './style.css';
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import ProductsList from "../pages/ProductsList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from '@mui/icons-material/Check';

const SearchBar = (props: any) => {
    //console.log(props, 'props in searchbar component')
    const [searchParams, setSearchParams] = useSearchParams();

    const [wishlist, setWishlist] = useState<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [routeType, setRouteType] = useState<any>(null);
    const [categoryId, setCategoryId] = useState<any>(null);


    const [searchLabel, setSearchLabel] = useState<string>('Search...');
    const [total, setTotal] = useState<any>(null);
    const [totalString, setTotalString] = useState<string>('');

    const [searchString, setSearchString] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');
    /*const [isSortActive, setIsSortActive] = useState<boolean>(false);
    const [sortItems, setSortItems] = useState<any>(null);
    const [sortBy, setSortBy] = useState<any>('');
    const [activeSort, setActiveSort] = useState<any>('');

    const [activeSizeFilter, setActiveSizeFilter] = useState<any>([]);
    const [activeCategoryFilter, setActiveCategoryFilter] = useState<any>([]);
    const [activeColorFilter, setActiveColorFilter] = useState<any>([]);
    const [activeGenderFilter, setActiveGenderFilter] = useState<any>([]);

    const [filterCategoryItems, setFilterCategoryItems] = useState<any>(null);
    const [filterColorItems, setFilterColorItems] = useState<any>(null);
    const [filterGenderItems, setFilterGenderItems] = useState<any>(null);
    const [filterSizeItems, setFilterSizeItems] = useState<any>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
    const [filterBy, setFilterBy] = useState<any>('');

    const [activeBoutiqueFilter, setActiveBoutiqueFilter] = useState<any>('');
    const [filterBoutiqueItems, setFilterBoutiqueItems] = useState<any>(null);

    const [activeLocaleFilter, setActiveLocaleFilter] = useState<any>(null);
    const filterLocaleItems = [
        {id: 1, name: 'France'},
        {id: 2, name: 'UK'},
        //{id: 3, name: 'USA'},
        //{id: 4, name: 'UAE'}
    ];*/

    const [modalType, setModalType] = useState<string>('sort'); // ['sort', 'filter'
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [languageParams, setLanguageParams] = useState<any>('');
    const [userLanguage, setUserLanguage] = useState<any>(null);

    const [filtersQueryString, setFiltersQueryString] = useState<any>('');

    useEffect(() => {
        if(props.searchQuery === 'products') {
            let filterParams: any = searchParams.get('filter');
            if(filterParams !== null) {
                let filterParamsObject = JSON.parse(filterParams);
                //console.log(filterParamsObject, 'filterParamsObject')
                if("tag" in filterParamsObject) {
                    setFiltersQueryString(`filter={"tag" : "${filterParamsObject.tag}"}`); //query params used in front end
                    setFilterString(`,"tag" : "${filterParamsObject.tag}"`); //query params appended in api request
                }

                if("q" in filterParamsObject) {
                    setSearchString(filterParamsObject.q);
                }
            }
        }
    }, []);



    const [user, setUser] = useState<any>(jwt(props.token));
    //console.log(user, 'user in searchbar component');

    /*const getSubCategories = async (categoryId: any, localeId: any = null) => {
        const internalLanguageParams = localeId !== null ? `,"chosen_cabana_locale_id":${parseInt(localeId)}` : `,"chosen_cabana_locale_id":${parseInt(props.wishlist.boutique.cabana_locale_id)}`;
        const response = await dataProvider.get(`sub-category?filter={"main_category_id": ${parseInt(categoryId)}, "cabana_locale_id" : ${props.wishlist.boutique.cabana_locale_id}${languageParams !== '' ? languageParams : internalLanguageParams}}`, props.token);
        if(response !== null) {
            //console.log(response, 'response in getSubCategories');
            setFilterCategoryItems(response);
        } else {
            console.log('No categories found');
        }

    }

    const getColors = async (categoryId: any) => {
        //console.log(products, 'products for color');
        //console.log(filterColorItems, 'filterColorItems');
        const response = await dataProvider.get(`colors?filter={"main_category_id": ${parseInt(categoryId)}, "cabana_locale_id" : ${props.wishlist.boutique.cabana_locale_id}}`, props.token);
        if(response !== null) {
            console.log(response, 'response in colors');
            const colors: any = [];
            if(response.length > 0) {
                response.map((item: any) => {
                    if(item.tracking_variant !== null && item.tracking_variant !== '' && item.tracking_variant !== undefined){
                        colors.push(item.tracking_variant);
                    }
                });
                setFilterColorItems(colors);
            }
        } else {
            console.log('No colors found');
        }
    }

    const getGenders = async (categoryId: any) => {
        //console.log(products, 'products for color');
        //console.log(filterColorItems, 'filterColorItems');
        const response = await dataProvider.get(`genders?filter={"main_category_id": ${parseInt(categoryId)}, "cabana_locale_id" : ${props.wishlist.boutique.cabana_locale_id}}`, props.token);
        if(response !== null) {
            console.log(response, 'response in genders');
            const genders: any = [];
            if(response.length > 0) {
                response.map((item: any) => {
                    if(item.category_id.includes('bebesgarcons')){
                        genders.push('bebesgarcons');
                    }else if(item.category_id.includes('bebefilles')){
                        genders.push('bebefilles');
                    }
                });
                let gendersUnique = genders.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                setFilterGenderItems(gendersUnique);
                console.log(genders, 'processed genders')
                console.log(gendersUnique, 'genders unique');
            }
        } else {
            console.log('No genders found');
        }
    }

    const getBoutiques = async () => {
        const filterString = user.role === 0 ? `?filter={"cabana_locale_id" : ${user.cabana_locale_id}}` : '';
        const response = await dataProvider.get(`boutique${filterString}`, props.token);
        if(response !== null) {
            //console.log(response, 'response in getBoutiques');
            if(response.total > 1) {
                setFilterBoutiqueItems(response);
                setIsFilterActive(true);
            }
        } else {
            console.log('No boutiques found');
        }
    }

    const getSizesColorsGendersAndCategories = async (languageId: any = null) => {
       const response = await dataProvider.get(`product?filter={"chosen_cabana_locale_id": "${languageId !== null ? languageId : 2}","cabana_locale_id": "${props.wishlist.boutique.cabana_locale_id}","wishlist_id": "${props.wishlist.id}"${languageParams}}&perPage=-1`, props.token);
        if (response !== null) {
            console.log(response, 'response in getSizes');
            //Remove comment if filter size is needed
           //setFilterSizeItems(response.size_labels);
           setFilterCategoryItems(response.sub_categories);
           setFilterColorItems(response.colors);
           setFilterGenderItems(response.genders)
       } else {
              console.log('No sizes found');
       }
    }*/


    useEffect(() => {
        //console.log(activeBoutiqueFilter, 'activeBoutiqueFilter in use effect');

        if(props.searchQuery === 'products') {
            //let language = null;
            //if(user.role === 2) {
            let chosenLocale = sessionStorage.getItem('locale') !== null ? sessionStorage.getItem('locale') : user.locale_id;
            if(chosenLocale === undefined ||
                chosenLocale === null ||
                chosenLocale === '' ||
                chosenLocale === 'null' ||
                chosenLocale === 'undefined') {
                chosenLocale = 2;
            }

            setLanguageParams(`,"chosen_cabana_locale_id":${parseInt(chosenLocale)}`);
                setUserLanguage(parseInt(chosenLocale));
                let language = parseInt(chosenLocale);
            //}

            if(props.routeType === 'catalogue') {
                //getSubCategories(props.category, parseInt(chosenLocale));
                //getColors(props.category);
                //getGenders(props.category);
                setCategoryId(parseInt(props.category));
                setTotal(props.products !== null ? props.products.total : '');
                setTotalString(props.products.total+' '+t('filterSort.products'));
                //setIsSortActive(true);
                //setSortItems(['Price - High first', 'Price - Low first']);

                /*if(props.products !== null && props.products.size_labels !== null) {
                    setIsFilterActive(true);
                    //Remove comment if filter size is needed
                    //setFilterSizeItems(props.products.size_labels);
                }*/
            } else {
                //getSizesColorsGendersAndCategories(language);
                //setIsFilterActive(true);
                setTotal(props.products !== null ? props.products.total : '');
                setTotalString(props.products.total+' '+t('filterSort.products'));
                //setIsSortActive(false);
            }
            //console.log(props, 'props in searchbar component');
            setRouteType(props.routeType);
            setWishlist(props.wishlist);
            //setWebWishlists(props.wishlist);

            /*if(props.activeFilters !== undefined && props.activeFilters !== null) {
                let activeFilters = JSON.parse(props.activeFilters);
                console.log(activeFilters, 'activeFilters in products');
                let sortString = '';
                if (props.activeSort !== undefined && props.activeSort !== null) {
                    sortString = props.activeSort;
                }
                handleActiveFilters(activeFilters, sortString);
            }*/

            setProducts(props.products);

            setIsLoading(props.isLoading);
            setSearchLabel(t('searchProductLabel'));
            //console.log(products, 'products in searchbar component');
        }

    }, [props]);


    /*const handleActiveFilters = (activeFilters: any, sortString: any = '') => {

        //console.log(activeFilters, 'activeFilters');

        let searchString: any = '';

        if(activeFilters !== null) {
            if(activeFilters.sub_category_id !== undefined) {
                console.log(activeFilters.sub_category_id, 'activeFilters.sub_category_id')
                if(activeCategoryFilter.length === 0) {
                    setActiveCategoryFilter(activeFilters.sub_category_id);
                }
            }

            if(activeFilters.color !== undefined) {
                if(activeColorFilter.length === 0) {
                    activeFilters.color.map((item: any) => {
                        activeColorFilter.push(`"${item}"`);
                    });
                    setActiveColorFilter(activeColorFilter);
                }
            }

            if(activeFilters.gender !== undefined) {
                if(activeGenderFilter.length === 0) {
                    activeFilters.gender.map((item: any) => {
                        activeGenderFilter.push(`"${item}"`);
                    });
                    setActiveGenderFilter(activeGenderFilter);
                }
            }

            if(activeFilters.size_label !== undefined) {
                console.log(activeFilters.size_label, 'activeFilters.size_label')
                let sizeLabels = activeFilters.size_label;
                sizeLabels.map((item: any) => {
                    activeSizeFilter.push(`"${item}"`);
                });
                setActiveSizeFilter(activeSizeFilter);
            }

            if(activeFilters.q !== undefined) {
                console.log(activeFilters.q, 'activeFilters.q')
                searchString = activeFilters.q;
                setSearchString(searchString);
            }

            if(props.searchQuery !== 'products') {
                if (user.role === 1) {
                    if (activeFilters !== null) {
                        let filterString = '';
                        if(props.searchQuery === 'giftee') {
                            filterString = `,"cabana_locale_id" : ${activeFilters}`;
                        } else {
                            filterString = `,"wishlist_cabana_locale_id" : ${activeFilters}`;
                        }
                        setActiveLocaleFilter(activeFilters);
                        search('', '', filterString);
                    } else {
                        setActiveLocaleFilter(null);
                    }

                    /*if(props.activeBoutiqueId !== null) { //TODO activate for SA
                        setActiveBoutiqueFilter(props.activeBoutiqueId);
                    } else {
                        setActiveBoutiqueFilter('');
                    }*/

    //            }
    //        }
    //    }

    /*    if(sortString !== '') {
            if(sortString === '["price_value","DESC"]') {
                setActiveSort('Price - High first');
            } else if(sortString === '["price_value","ASC"]') {
                setActiveSort('Price - Low first');
            }
        }
        //console.log(wishlist, 'wishlist in handleActiveFilters');
        //search(searchString, sortString, filterString);

    }*/

    const search = async (string: any, filterString: any = '') => {
        //console.log(filterString, 'filterString in search');
        //setFilterBy(filterString);
        setSearchString(string)
        //setSortBy(sortString);
        //let filterString = '';
        let sortString = '';
        let sort = '';
        let queryString = '';

        if(filterString === '') {
            if(props.setFilters !== undefined) {
                props.setFilters(null);
            }
        }

        if(sortString === '') {
            if(props.setSort !== undefined) {
                props.setSort(null);
            }
        }

        if(string !== '') {
            queryString = `,"q" : "${string}"`;
        }

        if(sortString !== '') {
            sort = `&sort=${sortString}`;
        }

        if(string.length > 2) {
            let response: any = '';
            if(props.searchQuery === 'products') {
                if(routeType == 'catalogue') {
                    props.setQuery(`?filter={"cabana_locale_id" :1,"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`);
                    //response = await dataProvider.get(`product?filter={"cabana_locale_id" :1,"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`, props.token);
                    setFiltersQueryString(`filter={"cabana_locale_id" :1,"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`);
                } else {
                    props.setQuery(`?filter={"cabana_locale_id" :1,"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`);
                    //response = await dataProvider.get(`product?filter={"cabana_locale_id" :1,"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`, props.token);
                    setFiltersQueryString(`filter={"cabana_locale_id" :1,"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`)
                }
            }

            if(props.searchQuery === 'wishlist'){
                //let filterWishlistString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterWishlistString = parseInt(user.role) === 0 ? `,"wishlist_cabana_locale_id" : ${user.cabana_locale_id}` : '';
                const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_cabana_locale_id" : ${user.cabana_locale_id}` : '';
                response = await dataProvider.get(`${props.searchQuery}?filter={"created_by": [0,1],"status": 1, "q": "${string}"${filterWishlistString}${filterString}${filterByRole}}&perPage=-1${sort}`, props.token);
            }

            if(props.searchQuery === 'webWishlist'){
                //let filterWishlistString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterWishlistString = parseInt(user.role) === 0 ? `,"wishlist_cabana_locale_id" : ${user.cabana_locale_id}` : '';
                const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_cabana_locale_id" : ${user.cabana_locale_id}` : '';
                response = await dataProvider.get(`wishlist?filter={"status": 1,"created_by": 2, "q": "${string}"${filterWishlistString}${filterString}${filterByRole}}&perPage=-1${sort}`, props.token);
            }

            if(props.searchQuery === 'giftee') {
                //let filterGifteesString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterGifteesString = parseInt(user.role) === 0 ? `,"cabana_locale_id" : "${user.cabana_locale_id}"` : '';
                response = await dataProvider.get(`user?filter={"role":"2","q": "${string}"${filterGifteesString}${filterString}}&perPage=-1${sort}`, props.token);
            }

            if (response !== null) {
                if (props.searchQuery === 'products') {
                    setTotal(props.products.total !== null ? props.products.total : '');
                    setTotalString(t('filterSort.products')+' '+props.products.total);
                    //setProducts(response !== null ? response : null);
                }
            } else {
                console.log('error');
            }
        } else if(string.length === 0) {
            let response: any = '';
            if(props.searchQuery === 'products') {
                if(routeType == 'catalogue') {
                    props.setQuery(`?filter={"cabana_locale_id": "1", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`)
                    //response = await dataProvider.get(`product?filter={"cabana_locale_id": "1", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`, props.token);
                    setFiltersQueryString(`filter={"cabana_locale_id": "1", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`);
                } else {
                    props.setQuery(`?filter={"cabana_locale_id": "1","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`);
                    //response = await dataProvider.get(`product?filter={"cabana_locale_id": "1","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`, props.token);
                    setFiltersQueryString(`filter={"cabana_locale_id": "1","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`);
                }
            } else {
                if(props.searchQuery === 'wishlist' || props.searchQuery === 'webWishlist') {
                    let createdByFilter = '';
                    if (props.searchQuery === 'wishlist') {
                        createdByFilter = '[0,1]';
                    } else if (props.searchQuery === 'webWishlist') {
                        createdByFilter = '2';
                    }
                    //console.log(filterString, 'filterString in search');
                    const filterWishlistByBoutique = filterString !== '' ? filterString : '';
                    const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_cabana_locale_id" : ${user.cabana_locale_id}` : '';
                    const filterWishlistString = props.searchQuery === 'webWishlist' || props.searchQuery === 'wishlist' ? `&filter={"created_by" : ${createdByFilter}, "status": 1 ${filterWishlistByBoutique}${filterByRole}}` : '';

                    response = await dataProvider.get(`${props.searchQuery !== 'webWishlist' ? props.searchQuery : 'wishlist'}?perPage=-1${filterWishlistString}${sort}`, props.token);
                } else {
                    const filterGifteesString = parseInt(user.role) === 0 ? `"role":2, "cabana_locale_id" : ${user.cabana_locale_id}${filterString}` : `"role":2 ${filterString}`;
                    response = await dataProvider.get(`user?perPage=-1&filter={${filterGifteesString}}${sort}`, props.token);
                }
            }

            if (response !== null) {
                //console.log(response);

                if (props.searchQuery === 'products') {
                    //setTotal('');
                    //setTotalString('');
                    //if(routeType == 'catalogue') {
                    //setProducts(response);
                    setTotal(props.products.total !== null ? props.products.total : '');
                    setTotalString('Products '+props.products.total);
                }
            }
        }
    }

    const renderElements = () => {
        if (props.searchQuery === 'products') {
            //console.log(props.wishlist, 'wishlist in searchbar component')
            //console.log(products, 'products in searchbar component')
            //console.log(props.routeType, 'routeType in searchbar component')
            //console.log(filtersQueryString, 'filtersQueryString in searchbar component')
            if(products === null || wishlist === null) return null;
            return (
                <ProductsList
                    category={routeType === 'catalogue' ? categoryId : null}
                    wishlist={wishlist}
                    products={products}
                    routeType={routeType}
                    isLoading={isLoading}
                    filtersQueryString={filtersQueryString}
                    sortQueryString={''}
                    setImagesLoaded={props.setImagesLoaded}
                />
            );
        }
    }

    const { t, i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [pageType, setPageType] = useState<string>(''); // ['catalogue', 'wishlist']

    return (
    <div className="SearchBarContainer">
        <form className="MobileSearchBar d-block mt-lg-2"
              onSubmit={(e) => {
                  e.preventDefault(); // Previene il refresh della pagina
                  search(searchString, filterString);
              }}
        >
            <TextField
                id="search-bar"
                style={{width: '100%'}}
                onInput={(e: any) => {
                    search(e.target.value, filterString);
                }}
                onSubmit={(e: any) => {
                    e.preventDefault();
                    search(searchString, filterString);
                }}
                label={searchString !== '' ? '' : searchLabel}
                value={searchString}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit"  aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
        <div className="SearchBarBottom">
            <span>{totalString}</span>
            <div className="d-flex justify-content-center align-items-center">
                <form className="DesktopSearchBar d-none"
                      onSubmit={(e) => {
                          e.preventDefault(); // Previene il refresh della pagina
                          search(searchString, filterString);
                      }}
                >
                    <TextField
                        id="search-bar"
                        style={{width: '100%'}}
                        onInput={(e: any) => {
                            search(e.target.value, filterString);
                        }}
                        label={searchString !== '' ? '' : t('searchShort')}
                        value={searchString}
                        variant="standard"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit" aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </div>
        </div>
        {renderElements()}
    </div>
    )
};

export default SearchBar;