import React, {useEffect, useState} from "react";

import headerBackground from '../../media/images/WishlistHeaderBackground.png';
import SocialShare from '../../media/images/SocialShare.svg';
import Contact from '../../media/images/Contact.svg';
import WhatsApp from '../../media/images/Whatsapp.svg';
import Facebook from '../../media/images/FacebookIcon.svg';
import LinkImage from '../../media/images/Link.svg';
import MailIcon from '../../media/images/IconMail.svg';
import DeliveryIcon from '../../media/images/DeliveryIcon.svg';


import {Link, useSearchParams} from 'react-router-dom';
import Header from '../Header';
//import ProductsList from "./ProductsList";

import {Loader} from "../Loader";

import {checkPathName, frontendBaseURL, mainRolesRoutes, rolesCodes, gifterPassword} from "../../global";
import jwt from "jwt-decode";

import { Alert, Collapse, IconButton } from '@mui/material';
import Modal from 'react-bootstrap/Modal';

import {WhatsappShareButton, EmailShareButton, FacebookShareButton} from "react-share";
import dataProvider from "../../provider/api-interface";

import '../../App.css';
import './Wishlist.css';

import { useTranslation, withTranslation } from 'react-i18next';
import {useNavigate} from "react-router";

import CloseIcon from "@mui/icons-material/Close";
//import SearchBar from "../search-engine/SearchBar";
import dayjs from "dayjs";

import {setFilterSortProducts} from "../search-engine/Utils";
import {decode} from "html-entities";
import SearchBar from "../search-engine/SearchBar";

interface Wishlist {
    id: number;
    name: string;
    description: string;
    event_date: string;
    created_at: string;
    items: any[];
    purchased_items: any[];
    user_name: any;
    user_surname: any;
    list_code: string;
}

interface WishlistItem {
    code: string;
}

function Wishlist() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    console.log('pathName', pathName);

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [wishlistUrl, setWishlistUrl] = useState<string>(window.location.href);

    const [productsListClass, setProductsListClass] = useState<string>('col-12 col-lg-6');

    const handleClose = () => setShowModal(false);

    const password = gifterPassword;

    //Use anywhere
    const [jwtToken, setJwtToken] = useState<string | null>(null);
    const [user, setUser] = useState<any>(null);
    //End use anywhere

    /*if(!checkPathName(pathName, token)) {
        navigate('/404');
    }*/

    const wishlistCode = window.location.pathname.split('/')[3];

    const [wishlist, setWishlist] = useState<any>(null);
    const [wishlistItems, setWishlistItems] = useState<WishlistItem[] | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState<any>('');
    const [activeFilters, setActiveFilters] = useState<any>(searchParams.get('filter'));
    const [products, setProducts] = useState<any>(null);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

    //('wishlistCode', wishlistCode);
    if(wishlistCode === undefined || wishlistCode === null || wishlistCode === '') {
        //navigate(`/${pathName}/404`);
    }

    //console.log('token', token)
    const [notificationText, setNotificationText] = useState<string>('');
    const [notificationType, setNotificationType] = useState<any>("info");
    const [notificationOpen, setNotificationOpen] = useState(false);

    const autoClose = () => {
        setTimeout(() => {
            setNotificationOpen(false);
        }, 3000);
    };

    React.useEffect(() => {
        async function fetchWishlist() {
            //let filters = '';
            //}
            let token: any = jwtToken === null ? localStorage.getItem('token') : sessionStorage.getItem('token');
            if(pathName === 'gifter') {
                await dataProvider.gifterLogin(password, wishlistCode).then(async (response: any) => {
                    const token: string = response.token;
                    localStorage.setItem('token', token);
                    setJwtToken(token);
                    let user: any = jwt(token);
                    setUser(user);
                    console.log(user, 'user')
                    if((user.role === 2 || user.role === 3) && pathName !== 'gifter') {
                        navigate('/login');
                    }
                    let data = await dataProvider.getWishlistByListCode(wishlistCode, token).then((data: any) => {
                        console.log('data wishlist', data)
                        if (data === null) {
                            //navigate(`/${pathName}/404`);
                            setIsLoading(false)
                            return;
                        }
                        setWishlist(data);
                        setWishlistUrl(frontendBaseURL + '/' + mainRolesRoutes.gifter + '/' + data.list_code);
                        if(data.products !== null) {
                            let filterSort: any = {
                                filter: '',
                                sort: '',
                                search: ''
                            };

                            if(activeFilters !== null) {
                                filterSort = setFilterSortProducts(activeFilters, '');
                            }

                            let queryString = `?filter={"wishlist_id": "${data.id}"${filterSort.filter}${filterSort.search}}&perPage=-1`;
                            if (query === '') {
                                setQuery(queryString);
                            }

                            fetchProducts(query !== '' ? query : queryString, token);

                        } else {
                            setIsLoading(false)
                        }
                    });
                });

            } else {
                setJwtToken(token);
                let user: any = jwt(token);
                console.log(user, 'user')
                if(user.role !== 1 && pathName === 'giftee') {
                    navigate('/login');
                }
                setUser(user);
                let data = await dataProvider.getWishlistByListCode(wishlistCode, token).then((data: any) => {
                    console.log('data wishlist', data)
                    if (data === null) {
                        //navigate(`/${pathName}/404`);
                        setIsLoading(false)
                        return;
                    }
                    setWishlist(data);
                    setWishlistUrl(frontendBaseURL + '/' + mainRolesRoutes.gifter + '/registry/' + data.list_code);
                    if(data.products !== null) {
                        let filterSort: any = {
                            filter: '',
                            sort: '',
                            search: ''
                        };

                        if(activeFilters !== null) {
                            filterSort = setFilterSortProducts(activeFilters, '');
                        }

                        let queryString = `?filter={"wishlist_id": "${data.id}"${filterSort.filter}${filterSort.search}}&perPage=-1`;
                        if (query === '') {
                            setQuery(queryString);
                        }

                        fetchProducts(query !== '' ? query : queryString, token);

                    } else {
                        setIsLoading(false)
                    }
                });
            }

            //setWishlistItems(data.items);
        }

        fetchWishlist();
    },[wishlistCode]);

    const fetchProducts = async (filterQuery: any, token: any) => {
        dataProvider.get(`product/${filterQuery}`, token)
            .then((response: any) => {
                if(response !== null){
                    setProducts(response);
                } else {
                    console.log('error')
                }
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if(products !== null) {
            setProductsListClass('col-12 col-lg-9');
        }
    }, [products]);

    const updateQuery = (newQuery: any) => {
        setQuery(newQuery);
        fetchProducts(newQuery, jwtToken);
    };

    const updateFilters = (newFilters: any) => {
        setActiveFilters(newFilters);
    }

    const addItemsButton = () => {
        if(pathName !== mainRolesRoutes.gifter) {
            if(wishlist.products === null) {
                return (
                    <Link to={`/${pathName}/registry/categories?id=${wishlist?.list_code}`} className="Fgift-button Fgift-wl-buttons">
                        {t("noProductsButton")}
                    </Link>
                );
            }

            return (
                <Link to={`/${pathName}/registry/categories?id=${wishlist?.list_code}`} className="Fgift-button Fgift-wl-buttons">
                    {t("addItemFromCatalogue")}
                </Link>
            )
        }

        if (pathName === mainRolesRoutes.gifter) {
            return '';

            /*return (
                <Link to={`/${pathName}/how-to-buy`} className="Fgift-button">
                    {t("howToBuyLabel")}
                </Link>
            )*/
        }

        return null;


    }

    const shareModal= () => {
        if(wishlist === null) {
            return null;
        }
        let shareParagraph = t('modalShareParagraphSA');
        let facebookShare: any = null;
        let whatsappShare: any = null;
        let emailShare: any = null;
        let cols = "col-6";

        let message: any = `${t('shareText')}\n\n${t('hereTheLink')}`;
        if(pathName === mainRolesRoutes.giftee) {
            shareParagraph = t('modalShareParagraphGIFTEE');
            cols = "col-3";
            facebookShare =
                <div className={cols}>
                    <FacebookShareButton
                        id="facebook-share-button"
                        url={wishlistUrl}
                        quote={t('shareText') + '\n'}
                    >
                    <div className="WishlistSocialShareIcons" style={{cursor: 'pointer'}}>
                        <img src={Facebook} alt="social share icon" style={{height: '48px', width: '48px'}} /><span>Facebook</span>
                    </div>
                </FacebookShareButton>
             </div>;
            whatsappShare = <div className={cols}>
                <WhatsappShareButton
                    url={wishlistUrl}
                    title={message}
                >
                    <div className="WishlistSocialShareIcons" style={{cursor: 'pointer'}}>
                        <img id="whatsapp-share-button" src={WhatsApp} alt="social share icon" style={{height: '48px', width: '48px'}} /><span>WhatsApp</span>
                    </div>
                </WhatsappShareButton>
            </div>;

            emailShare = <div className={cols}>
                <EmailShareButton
                    url={wishlistUrl}
                    subject={'Wedding Gift List - ' + wishlist.name}
                    body={t('shareText')}
                >
                    <div className="WishlistSocialShareIcons" style={{cursor: 'pointer'}}>
                        <img id="email-share-button" src={MailIcon} alt="social share icon" style={{height: '48px', width: '48px'}} /><span>E-mail</span>
                    </div>
                </EmailShareButton>
            </div>;
        }


        let gapStyle = {gap: '0px'};
        if(pathName === mainRolesRoutes.giftee){
            gapStyle = {gap: '15px'};
        }
        return (<Modal className="ShareModal right" show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modalShareTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{fontSize: '13px'}}>{shareParagraph}</div>
                <div id="share-buttons" className="row ShareIcons" style={gapStyle}>
                    {whatsappShare}
                    {/*facebookShare*/}
                    {emailShare}
                    <div className={cols}>
                        <div className="WishlistSocialShareIcons" style={{cursor: 'pointer'}}  onClick={() => {
                            //let copyMessage = `${t('shareText')}\n\n${t('hereTheLink')}${wishlistUrl}`;
                            let copyMessage = `${wishlistUrl}`;

                            navigator.clipboard.writeText(copyMessage);
                            setShowModal(false);
                            setNotificationType('info');
                            setNotificationText(t('wishlistUrlCopied'));
                            setNotificationOpen(true);
                            autoClose();
                        }}>
                            <img id="copy-share-button" src={LinkImage} alt="social share icon" style={{height: '48px', width: '48px'}} /><span>{t('copyLinkButton')}</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
    }

    const sendShareMailToGiftee = async () => {

        let response = await dataProvider.shareWishlistToGiftee(wishlist.id, jwtToken);

        if(response === null) {
            setNotificationOpen(true);
            setNotificationType('error');
            setNotificationText("Error sending mail");
            setShowModal(false);
            autoClose();
        } else {
            setNotificationOpen(true);
            setNotificationType('success');
            setNotificationText("Mail sent successfully");
            setShowModal(false);
            autoClose();
        }

    }

    const getLoader = () => {
        if(isLoading) {
            return <Loader />;
        }

        return null;
    }

    const setHeader = () => {


        if(wishlist === null) {
            return <Header hideLanguageSelect={true}></Header>;
        }

        return (
            <Header hideLanguageSelect={true}></Header>
        );

    }

    if(wishlist == null) {
        return (
            <div>
                {setHeader()}
                {/*getLoader()*/}
            </div>
        )
    }

    const renderWishlist = (): JSX.Element => {
        if(wishlist !== null) {
            if (products === null) {
                return (
                    <div className="NoProducts">
                        <div className="NoProductsText">{t('noProductsFound')}</div>
                        <div className="NoProductsSubtitle">{t('noProductsSubtitle')}</div>
                    </div>
                );
            } else {
                return (
                    <SearchBar
                        products={products}
                        wishlist={wishlist}
                        locale={1}
                        isLoading={isLoading}
                        routeType={'wishlist'}
                        searchQuery={'products'}
                        activeFilters={activeFilters}
                        setQuery={updateQuery}
                        setFilters={updateFilters}
                        setImagesLoaded={setImagesLoaded}
                        token={jwtToken} />
                );
            }
        } else {
            return (
                <div className="NoProducts">
                    <div className="NoProductsText">{t('noProductsFound')}</div>
                    <div className="NoProductsSubtitle">{t('noProductsSubtitle')}</div>
                </div>
            );
        }
    };

    const handleShare = async () => {
        //console.log(wishlistUrl)
        if(wishlist !== null) {
            /*if (navigator.share && parseInt(user.role) === 2) { //native share only for giftee
                try {
                    await navigator.share({
                        title: 'Dior Gift List - ' + wishlist.name,
                        text: t('shareText') + '\n\n' + t('hereTheLink') + wishlistUrl + `\n${t('password')}: ` + wishlistPassword + '\n\n',
                        url: wishlistUrl,
                    });
                    console.log('Sharing ok!');
                } catch (error) {
                    console.error('Error during sharing:', error);
                }
            } else {
                //console.log('Your Browser does not support Web Share API.');
                setShowModal(true)
            }*/
            setShowModal(true)
        }
    }

    const renderWishlistText = () => {
        let wishlistText = t('wishlistText');

        if(user.role === 2) {
            return <div className="WishlistText align-items-lg-center">
                <p style={{marginBottom: '-24px'}}>{wishlistText}</p>
            </div>
        }

        return '';
    }

    const renderWishlistPriceDisclaimer = () => {
        //if(wishlist.products === null) {
            return '';
        //}

        return <div className="WishlistPriceDisclaimer">
            <p>{t('wishlistPriceDisclaimer')}</p>
        </div>
    }

    const wishlistContactButton = () => {
        let contactButton: any = '';
        let phoneNumber = '+39 (0) 27423 5292';
        let sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');

        let contactNumber = `https://wa.me/${sanitizedPhoneNumber}`;

        contactButton = <Link className="WishlistSocialShareButton"  to={contactNumber} >
                <img src={Contact} alt="social share icon" style={{height: '20px', width: '20px', paddingRight: '3px'}} /><span className="ShareText">{t('contactText')}</span>
            </Link>;


        return <div className="ShareContainer">
            {contactButton}
        </div>
    }

    const editWishlistButton = () => {
        let editButton: any = '';
        if(user.role === 1 && wishlist.delivery_address_line_1 !== null) {
            editButton = <Link to={`/giftee/registry/${wishlist.list_code}/edit`} style={{marginLeft: '6px'}} className="WishlistSocialShareButton">
                <img src={DeliveryIcon} alt="delivery icon" style={{height: '20px', width: '20px', paddingRight: '3px'}} /><span className="ShareText">Delivery details</span>
            </Link>
        }

        return editButton;
    }

    const wishlistShareButton = () => {
        let shareButton: any = '';
        if(user.role === 1) {
            shareButton = <div className="Fgift-button Fgift-button-reverted Fgift-wl-buttons" onClick={() => {handleShare()}}>
                {t('shareButton')}
            </div>
        }

        return shareButton;
    }

    const wishlistAddDelivery = () => {
        let addDeliveryInfo: any = '';
        const screenWidth = window.innerWidth;
        if(user.role === 1) {
            addDeliveryInfo = <Link to={`/giftee/registry/${wishlist.list_code}/edit`} className="Fgift-button AddDeliveryInfo Fgift-button-reverted Fgift-wl-buttons">
                Add delivery<br/> information
            </Link>
        }

        return addDeliveryInfo;
    }
    //console.log(wishlist, 'TEST')
    return (
        <div>
            {setHeader()}
            <div className="row WishlistDates d-flex d-lg-none">
                <div className="col-12 DatesContainer">
                    <div className="WishlistDateContainer">
                        <p style={{fontWeight: '700'}}>{t('startDate') + ': ' + dayjs(wishlist.event_date).format('DD/MM/YYYY')}</p>
                    </div>
                    <div className="WishlistDateContainer">
                        <p>{t('endDate') + ': ' + dayjs(wishlist.end_date).format('DD/MM/YYYY')}</p>
                    </div>
                </div>
            </div>
            <div className="WishlistHeader" style={{background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${headerBackground})`}}>
            <div className="row">
                    <div className="col-12">
                        <div className="WishlistTitleContainer">
                            <h4>{decode(wishlist.name)}</h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <div style={{textTransform: "none"}} className="HomeUserName">{t('createdBy')}: {wishlist.giftee.name} {wishlist.giftee.surname}</div>
                    </div>
                    <div className="col-12">
                        <div className="WishlistSocialShareContainer">
                            {wishlistContactButton()}
                            {editWishlistButton()}
                        </div>
                    </div>
                </div>
            </div>
            {renderWishlistText()}
            <div className="WishlistItemsContainer row px-lg-4">
                <div className="col-3 d-none d-lg-flex flex-column justify-content-center align-items-start">
                    <div className="WishlistDesktopDates">
                        <div className="WishlistDateContainer">
                            <p style={{fontWeight: '700'}}>{t('startDate') + ': ' + dayjs(wishlist.event_date).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="WishlistDateContainer">
                            <p>{t('endDate') + ': ' + dayjs(wishlist.end_date).format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                    <div className="ButtonContainer d-none flex-column align-items-baseline gap-3 cursor-pointer d-lg-flex position-relative"
                         style={{width: '80%', marginLeft: '48px'}}>
                        {addItemsButton()}
                        {wishlist.delivery_address_line_1 === null ? wishlistAddDelivery() : wishlistShareButton()}
                    </div>
                </div>
                <div className={productsListClass}>
                    <Collapse className="FgiftCustomCollapse" in={notificationOpen}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setNotificationOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity={notificationType}
                        >
                            {notificationText}
                        </Alert>
                    </Collapse>
                    {renderWishlist()}
                </div>
                <div className="col-3 d-none d-lg-flex"></div>
            </div>
            {renderWishlistPriceDisclaimer()}
            <div className="ButtonContainer d-flex flex-column gap-3 d-lg-none">
                {addItemsButton()}
                {wishlist.delivery_address_line_1 === null ? wishlistAddDelivery() : wishlistShareButton()}
            </div>
            {shareModal()}
        </div>
    )



}

export default withTranslation()(Wishlist);