import React, {useState} from 'react';
import Header from '../Header';
import {useLocation, useNavigate} from "react-router";
import { useTranslation, withTranslation } from 'react-i18next';
import './style.css';

import {TextField, Alert, Collapse, IconButton, InputAdornment, Checkbox} from "@mui/material";
import {validateEmail} from "../../services/CheckUtils";
import CloseIcon from "@mui/icons-material/Close";
import dataProvider from "../../provider/api-interface";
import {frontendBaseURL, isJwtExpired, mainRolesRoutes, rolesCodes} from "../../global";
import jwt from "jwt-decode";

function ResetPassword() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [email, setEmail] = useState('');

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentials'));

    const location = useLocation();

    let jwtToken: any = null;

    if(localStorage.getItem('token') !== null){
        jwtToken = localStorage.getItem('token');
    } else if(sessionStorage.getItem('token') !== null) {
        jwtToken = sessionStorage.getItem('token');
    }

    if (
        jwtToken !== null
    ) {
        console.log(jwtToken);
        localStorage.clear();
        sessionStorage.clear();
        /*if (!isJwtExpired(jwtToken)) {
            let user: any = jwt(jwtToken!);
            let roleCode: number = parseInt(user.role);
            let role = rolesCodes[roleCode];
            if(roleCode === 0) {
                navigate(`/${mainRolesRoutes[role]}/`);
            } else {
                navigate(`/${mainRolesRoutes[role]}/login-success`);
            }
        }*/
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const handleResetPasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(email === '') {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t('emailNotEmpty'));
            return;
        }

        if (isEmailValid) {
            console.log("Email: " + email);
            await dataProvider.requestResetPassword(email).then((response: any) => {
                console.log(response);
                setAlertOpen(false);
                setAlertType("success");
                setAlertText(t('passwordSent'));
                setAlertOpen(true);
                setTimeout(() => {
                    navigate(`/login`);
                }, 2000);
            });

        } else {
            setAlertOpen(true);
            setAlertType("error");
            setAlertText(t('emailNotValid'));
            return;
        }
    }

    return (
        <div>
            <Header />
            <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                <Alert severity={alertType}
                       onClose={() => setAlertOpen(false)}
                       action={
                           <IconButton
                               aria-label="close"
                               color="inherit"
                               size="small"
                               onClick={() => {
                                   setAlertOpen(false);
                               }}
                           >
                               <CloseIcon fontSize="inherit" />
                           </IconButton>
                       }
                >
                    {alertText}
                </Alert>
            </Collapse>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="col-12 col-lg-6">
                    <form className="ResetPassword" onSubmit={handleResetPasswordSubmit}>
                        <div className="FormTitle"><h4>{t('resetPasswordTitle')}</h4></div>
                        <div className="EmailContainer">
                            <div className="FormLabel"><span>EMAIL</span></div>
                            <TextField
                                label={t("emailLabel")}
                                value={email}
                                onChange={handleEmailChange}
                                error={!isEmailValid}
                                variant="standard"
                            />
                        </div>
                        <div className="ButtonContainer">
                            <button type="submit" className="Fgift-button">
                                Reset password
                            </button>
                        </div>
                    </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );


}

export default withTranslation()(ResetPassword);