import React, { useEffect, useState } from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import {useNavigate} from "react-router";
import jwt from "jwt-decode";

import Header from '../Header';
import ImageSlider from '../slider/ImageSlider';
import {
    Select,
    MenuItem,
    InputLabel,
    SelectChangeEvent,
    Collapse,
    Alert,
    IconButton,
    CircularProgress
} from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

import {checkPathName, mainRolesRoutes} from "../../global";

import { useTranslation, withTranslation } from 'react-i18next';

import './Product.css';
import dataProvider from "../../provider/api-interface";
import {Loader} from "../Loader";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ProductVariation {
    sku: string;
    title: string;
    code: string;
    size_label: string;
    price_value: string;
    price_currency: string;
    tracking: string;
}

interface GalleryImages {
    image_thumb_uri: string;
    image_zoom_uri: string;
}

interface Characteristic {
    characteristic: string
}

interface ProductItem {
    id: string;
    product_code: string;
    title: string;
    subtitle: string;
    description: string;
    sub_universe: string;
    characteristics: Characteristic[];
    size_and_fit: string;
    categoryId: string;
    image_url: string;
    gallery_images: GalleryImages[];
    variations: ProductVariation[];
}

function Product() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [searchParams, setSearchParams] = useSearchParams();
    const [product, setProduct] = useState<any>({});

    const [productAdded, setProductAdded] = useState<any>(null);

    const [selectedVariation, setSelectedVariation] = useState<number>(0);
    const [variationSelectDisabled, setVariationSelectDisabled] = useState<boolean>(false);
    const [price, setPrice] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [loadingAvailability, setLoadingAvailability] = useState<boolean>(false);

    const listCode: any = searchParams.get('wishlistId');
    const productId: any = searchParams.get('id');
    const type = searchParams.get('type');
    const purchased = searchParams.get('purchased');
    const filtersQueryString = searchParams.get('filter');
    const sortQueryString = searchParams.get('sort');
    const categoryId = searchParams.get('categoryId');

    const [wishlistId, setWishlistId] = useState<string>('');

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertText, setAlertText] = useState<string>("");
    const [alertType, setAlertType] = useState<"error" | "success" | "warning">("error");

    const [productAvailable, setProductAvailable] = useState<boolean>(false);
    const [loadingStyle, setLoadingStyle] = useState<any>({height:'100vh'});

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    const jwtToken: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    //Use anywhere
    /*if(!checkPathName(pathName, jwtToken)) {
        navigate('/404');
    }*/

    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    /*if(!checkPathName(pathName, jwtToken)) {
        navigate('/404');
    }*/

    /*if((listCode === undefined || listCode === null || listCode === '') &&
        (productId === undefined || productId === null || productId === '') &&
        (type === undefined || type === null || type === '')) {
        navigate(`/${pathName}/404`);
    }*/

    const [wishlist, setWishlist] = useState<any>(null);
    const [salesAssistant, setSalesAssistant] = useState<any>(null);
    const [locale, setLocale] = useState<any>(null);
    const [boutique, setBoutique] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const getSalesAssistant = async (salesAssistantId: any) => {
            await dataProvider.getOne('user', salesAssistantId, jwtToken).then((response: any) => {
                //console.log(response, 'sales assistant');
                if(response !== null && response.data !== null) {
                    setSalesAssistant(response);
                }
            });
        }
        const fetchWishlist = async () => {
            //let languageFilter = '';
            //if(user.role === 2) {
            let chosenLocale = sessionStorage.getItem('locale') !== null ? sessionStorage.getItem('locale') : user.locale_id;
            if(chosenLocale === undefined ||
                chosenLocale === null ||
                chosenLocale === '' ||
                chosenLocale === 'null' ||
                chosenLocale === 'undefined') {
                chosenLocale = 2;
            }

            //}
            await dataProvider.getWishlistByListCode(listCode, jwtToken).then((response: any) => {
                console.log(response, 'wishlist');
                setWishlistId(response.id)
                setWishlist(response);
                setLocale(1);
                fetchProduct(1)

                if(response.products && response.products.length > 0) {
                    const productAdded = response.products.find((product: any) => product.product_code === productId);
                    if(productAdded !== undefined) {
                        setProductAdded(productAdded);
                        setVariationSelectDisabled(true);
                        setButtonDisabled(true);
                    }
                    //console.log(productAdded, 'productAdded');
                }

                if(user.role === 1 || user.role === 2) { //sales assistant
                    getSalesAssistant(response.sales_assistant.id);
                    setBoutique(response.boutique);
                }
                //return response;
                //console.log(response);
            }).catch((error: any) => {
                console.log(error);
                //navigate(`/${pathName}/404`);
            })
        }

        //console.log(wishlist);
        const fetchProduct = async (localeId: any) => {
            //let languageParam = '';
            //if(user.role === 2) {

            let languageParam = `,"chosen_cabana_locale_id":1`;
            //}
            await dataProvider.get(`product/${productId}?filter={"cabana_locale_id" : ${localeId}${languageParam}}`, jwtToken).then((response: any) => {
                if(response !== null) {
                    console.log(response, 'product');
                    setProduct(response);
                    //check if product is available
                    if(response.hasOwnProperty('variations')) {
                        setLoadingStyle({height: '100vh', opacity: '0.5'});
                        setIsLoading(true);
                        setLoadingAvailability(true);
                        dataProvider.checkProductAvailability(response.variations[0].sku).then((response: any) => {
                            setLoadingAvailability(false);
                            console.log(response, 'product availability');
                            if(response[0].hasOwnProperty('availability')) {
                                if(parseInt(response[0].availability) > 0) {
                                    setProductAvailable(true);
                                } else {
                                    setAlertText("Product not available at the moment");
                                    setAlertType("warning");
                                    setAlertOpen(true);
                                    autoClose();
                                }
                            }
                            setIsLoading(false);
                        });
                    }
                }
                setIsLoading(false);
                console.log(response, 'product');
            });
        }
        fetchWishlist();
    }, []);


    const [pageType, setPageType] = useState<string | null>(type);

    const currencySymbol = (currency: string): string => {
        let currencySymbol = '';
        if (currency === 'EUR') {
            currencySymbol = '€';
        } else if (currency === 'USD') {
            currencySymbol = '$';
        } else if (currency === 'GBP') {
            currencySymbol = '£';
        } else if (currency === 'JPY') {
            currencySymbol = '¥';
        } else {
            currencySymbol = 'Dhs.';
        }
        return currencySymbol;
    };

    const handleVariationChange = (event: SelectChangeEvent<number>) => {
        const key = event.target.value as number;
        setSelectedVariation(key);
        let priceToSet: string;
        if('variations' in product){
            //const priceValue = product.variations[key].price_value;
            let priceValue: any = parseFloat(product.variations[key].price_value);
            priceValue = priceValue.toFixed(2);
            priceValue = priceValue.toString().replace('.', ',');
            const currency = product.variations[key].price_currency;
            priceToSet = priceValue + ' ' + currencySymbol(currency);
        }else{
            priceToSet = '';
        }

        setPrice(priceToSet);
    };

    const variationSelect = (product: any): JSX.Element | null => {
        if(productAdded !== null) {
            const variation = productAdded.variation;
            let sizeLabel = variation.size_label;

            //console.log(variation, 'variation');
            if(variation.size_label === "U") {
                return null;
            }

            product.variations.map((parentVariation: any) => {
                if(parentVariation.hasOwnProperty('translation')){
                    if(parentVariation.sku === variation.sku) {
                        sizeLabel = parentVariation.translation.size_label;
                    }
                }
            });

            return (
                <div className="ProductVariationSelect">
                    <Select
                        labelId="variation-select-label"
                        id="variation-select"
                        value={variation.id}
                        disabled={true}
                        IconComponent={() => null}
                        variant={'standard'}
                    >
                        <MenuItem className="ProductVariations" key={variation.id} value={variation.id}>
                            {sizeLabel} - <strong>{variation.price_value}{variation.price_currency === 'EUR' ? ',00' : '.00'} {currencySymbol(variation.price_currency)}</strong>
                        </MenuItem>
                    </Select>
                </div>
            );
        } else {
            const variations = product.variations;
            //console.log(variations, 'variations')
            if (variations.length > 1) {
                let iconComponentProp = variationSelectDisabled ? () => null : ExpandMoreIcon;
                return (
                    <div className="ProductVariationSelect">
                        <Select
                            labelId="variation-select-label"
                            id="variation-select"
                            value={selectedVariation}
                            onChange={handleVariationChange}
                            disabled={variationSelectDisabled}
                            IconComponent={iconComponentProp}
                            variant={'standard'}
                            label={t('selectSize')}
                        >
                            {variations.map((variation: any, index: any) => (
                                <MenuItem className="ProductVariations" key={index} value={index}>
                                    {variation.hasOwnProperty('translation') ? variation.translation.size_label : variation.size_label} - <strong>{variation.price_value}{variation.price_currency === 'EUR' ? ',00' : '.00'} {currencySymbol(variation.price_currency)}</strong>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                );
            } else {
                return null;
            }
        }
        //console.log(selectedVariation);
        //console.log(variationSelectDisabled);

    };

    const sizeAndFit = (product: any): JSX.Element => {
        if(product.size_and_fit !== null &&
            product.size_and_fit !== '' &&
            product.size_and_fit !== undefined &&
            product.size_and_fit !== 'null' &&
            product.size_and_fit !== 'undefined') {
            return <div className="ProductSizeAndFit">
                <strong>{t('sizeAndFit')}</strong><br/>
                {product.size_and_fit}<br/><br/>
            </div>;
        } else {
            return <span></span>;
        }
    }

    const handleDimensions = (characteristics: any) => {
        if(characteristics === null || characteristics === undefined || characteristics.length === 0) {
            return <span></span>;
        }

        const metadata = JSON.parse(characteristics[0].characteristic);
        console.log(metadata, 'metadata');

        return (
            <div className="ProductDimensionsContainer row">
                <div className="col-3 d-flex flex-column">
                    <div className="ProductDimensionsTitle">Width</div>
                    <div className="ProductDimensionsValue">{metadata.width?.value}{metadata.width?.unit}</div>
                </div>
                <div className="col-3 d-flex flex-column">
                    <div className="ProductDimensionsTitle">Height</div>
                    <div className="ProductDimensionsValue">{metadata.height?.value}{metadata.height?.unit}</div>
                </div>
                <div className="col-3 d-flex flex-column">
                    <div className="ProductDimensionsTitle">Diameter</div>
                    <div className="ProductDimensionsValue">{metadata.diameter?.value}{metadata.diameter?.unit}</div>
                </div>
                <div className="col-3 d-flex flex-column">
                    <div className="ProductDimensionsTitle">Length</div>
                    <div className="ProductDimensionsValue">{metadata.length?.value}{metadata.length?.unit}</div>
                </div>
            </div>
        );
    }

    const getProduct = (): JSX.Element => {
        if (isLoading) {
            return <div style={loadingStyle}>
                <Loader/>
            </div>
        }

        if (Object.keys(product).length > 0 && "gallery_images" in product && "variations" in product) {
            const gallery: any = product.gallery_images;
            let imageSlider = <ImageSlider imagesSlide={gallery} altTitle={product.title} />;
            let imageContainerStyle: any = {};
            if(gallery === null) {
                imageContainerStyle = {height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(179 179 179)'};
                imageSlider = <BrokenImageIcon style={{fontSize: '100px', color: '#f0f0f0'}} />;
            }
            let priceValue: any = '';
            if(productAdded !== null) {
                //console.log(productAdded, 'productAdded')
                priceValue = parseFloat(productAdded.variation.price_value);
            } else {
                priceValue = parseFloat(product.variations[selectedVariation].price_value)
            }
            //console.log(priceValue, 'priceValue')
            priceValue = priceValue.toFixed(2);
            priceValue = product.variations[selectedVariation].price_currency === 'EUR' ? priceValue.toString().replace('.', ',') : priceValue.toString();
            const symbol = currencySymbol(product.variations[selectedVariation].price_currency);

            let priceString = '';
            if (price === '') {
                priceString = priceValue + ' ' + symbol;
            } else {
                priceString = price;
            }
            return (
                <div className="ProductContainer row no-gutter">
                    <div style={imageContainerStyle} className="ProductImageContainer col-12 col-lg-6">
                        {imageSlider}
                    </div>
                    <div className="col-12 col-lg-6 ProductInfosContainer">
                        <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setAlertOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                severity={alertType}
                            >{alertText}</Alert>
                        </Collapse>
                        <div className="row ProductInfosColumn w-100">
                            <div className="col-12 col-lg-2 d-md-block d-none">
                            </div>
                            <div className="col-12 col-lg-8 ProductDescriptionContainer">
                                <div className="ProductInfoContainer row">
                                    {/*<div className="ProductInfo col-12">
                                        <div className="ProductCode">{t('referenceLabel')}: {product.variations[0].sku}</div>
                                    </div>*/}
                                    <div className="ProductInfo col-6 mt-4">
                                        <div className="ProductTitle">{product.title}</div>
                                    </div>
                                    <div className="ProductPrice col-6 mt-4">{priceString}</div>
                                    <div className="col-12 mt-2">
                                        <div className="ProductSubtitle">{product.subtitle}</div>
                                    </div>
                                </div>
                                {/*variationSelect(product)*/}
                                <div className="ProductDescription">
                                    {product.description}
                                    <br/><br/>
                                    {sizeAndFit(product)}
                                </div>
                                <div className="w-100">
                                    {handleDimensions(product.characteristics)}
                                </div>
                                <div className="d-none d-lg-block">
                                    <div style={{marginTop: '61px'}}
                                         className={loadingAvailability ? 'd-block' : 'd-none'}><CircularProgress/>
                                    </div>
                                    {productButton()}
                                </div>
                            </div>
                            <div className="col-12 col-lg-2 d-md-block d-none">
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div>Product not found</div>;
        }
    };

    const productButton = (): JSX.Element => {

        if(pathName === mainRolesRoutes.giftee && pageType !== 'catalogue') {
            return <span></span>;
        }

        /*if((pathName === mainRolesRoutes.salesAssistant || //sales assistant mark product as purchased
            pathName === mainRolesRoutes.callCenter) &&
            pageType !== 'catalogue') {
            let wishlistProductId = '';
            if(productAdded !== null) {
                wishlistProductId = productAdded.wishlist_x_product_id;
                const purchasedInWl = productAdded.gifter_id === null ? false : true;
                if((purchased !== null && parseInt(purchased) === 1) || purchasedInWl) {
                    return <span></span>;
                }

                return (
                    <Link to={`/${pathName}/gifter-details?id=${wishlistProductId}&wishlistId=${wishlist.list_code}`} id="markAsPurchased-button" className="Fgift-button" style={{ marginTop: '20px' }}>
                        {t('markAsPurchased')}
                    </Link>
                );
            }
        }*/
        let className = 'Fgift-button';
        if(loadingAvailability) {
            className = 'Fgift-button d-none';
        } else {
            className = 'Fgift-button';
        }

        if((pathName === 'gifter') &&
            pageType !== 'catalogue') {
            console.log(productAdded, 'productAdded');
            let wishlistProductId = '';
            if(productAdded !== null) {
                wishlistProductId = productAdded.wishlist_x_product_id;
                if(purchased !== null && parseInt(purchased) === 1) {
                    return <span></span>;
                }

                if(!productAvailable) {
                    return <span></span>;
                }

                return (
                    <Link to={`/${pathName}/finalize-gift/${wishlistProductId}?listCode=${listCode}`} id="markAsPurchased-button" className={className} style={{ marginTop: '20px' }}>
                        {t('markAsPurchased')}
                    </Link>
                );
            }
        }

        if(productAdded !== null) {
            if(
                (productAdded.gifter_id !== null || productAdded.gifter_id !== '')
                && (productAdded.message_alias !== null || productAdded.message_body !== null)
            ) {
                return <span></span>;
            }
        }
        console.log(purchased, 'purchased');
        if((purchased !== null && parseInt(purchased) === 1)) {
            return <span></span>;
        }

        return ( //catalogue view
            <button onClick={addProductToWishlist} disabled={!productAvailable || buttonDisabled} className={className} style={{ marginTop: '20px', width: '100%' }}>
                {t('addItemProductPage')}
            </button>
        );

    }

    let link: any = '';
    let filters: any = '';
    let sort: any = '';

    if(pageType === 'catalogue') {
        if(filtersQueryString !== null) {
            filters = "&filter=" + filtersQueryString;
        }

        if(sortQueryString !== null) {
            sort = "&sort=" + sortQueryString;
        }

        link = `/${pathName}/registry/catalogue?id=${wishlist?.list_code}&categoryId=${categoryId}${filters}${sort}`;
    } else {
        if(filtersQueryString !== null) {
            filters = "?filter=" + filtersQueryString;
        }

        if(sortQueryString !== null) {
            if(filters !== '') {
                sort = "&sort=" + sortQueryString;
            } else {
                sort = "?sort=" + sortQueryString;
            }
        }

        link = `/${pathName}/registry/${wishlist !== null ? wishlist.list_code : ''}${filters}${sort}`;
    }

    const addProductToWishlist = async () => {
        setButtonDisabled(true);
        const wishlist_id = wishlist.id;
        const product_id = product.id;
        const variation_sku = product.variations[0].sku;
        const fromGiftee = pathName === mainRolesRoutes.giftee;

        await dataProvider.addProductToWishlist(wishlist_id, product_id, variation_sku, fromGiftee, jwtToken).then((response: any) => {
            //console.log(response);
            if(parseInt(response.status) === 200 || parseInt(response.status) === 201) {
                setAlertText(t('productAddedGl'));
                setAlertType("success");
                setAlertOpen(true);
                setTimeout(() => {
                    navigate(link);
                }, 2000);
            } else {
                setAlertText(t('productAddedError'));
                setAlertType("error");
                setAlertOpen(true);
                setButtonDisabled(false);
                autoClose();
            }
        }).catch((error: any) => {
            //console.log(error);
            setAlertText(t('productAddedError'));
            setAlertType("error");
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
        });
    }

    let backToText = t('backTo') + ' catalogue';
    if(pageType !== 'catalogue' && wishlist !== null) {
        backToText = t('backTo') + ' ' + wishlist.name;
    }

    let wishlistUrl = `/${pathName}/registry/${listCode}`;

    return (
        <div className="ProductPageContainer">
            <Header /*hideLanguageSelect={user.role === 2}*/ link={link} textLink={backToText} wishlistUrl={wishlistUrl} hideLogout={true} showWishlistLink={true}  />
            {getProduct()}
            <div className="ButtonContainer d-flex d-lg-none" style={{marginTop: "-21px", zIndex: '1000', width: 'calc(100% - 40px)'}}>
                <div className={loadingAvailability ? 'd-block' : 'd-none'}><CircularProgress /></div>
                {productButton()}
            </div>
        </div>
    );
}

export default withTranslation()(Product);
