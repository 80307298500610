import Header from "../Header";
import jwt from "jwt-decode";
import {checkPathName} from "../../global";
import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import dataProvider from "../../provider/api-interface";
import {Link} from "react-router-dom";


import Dinnerware from "../../media/images/Dinnerware.png";
import Glassware from "../../media/images/Glassware.jpg";
import TableTop from "../../media/images/Tabletop.jpg";
//import Ceramics from "../../media/images/Ceramics.png";
//import Napkins from "../../media/images/Napkins.png";
//import TableLines from "../../media/images/TableLines.png";
import TableLines from "../../media/images/Tablelinens.jpeg";


import './Categories.css';
import headerBackground from "../../media/images/GiftlistHeader.png";

import FooterCabana from "./FooterCabana";

function Categories() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = useState<any>(null);

    //temp categories to use
    const [tableLinesId, setTableLinesId] = useState<number>(8);
    const [dinnerwareId, setDinnerwareId] = useState<number>(3);
    const [glasswareId, setGlasswareId] = useState<number>(4);
    const [tabletopId, setTabletopId] = useState<number>(7);

    //const [ceramicsId, setCeramicsId] = useState<number>(5);
    //const [napkinsId, setNapkinsId] = useState<number>(6);

    const listCode = searchParams.get('id');
    console.log(listCode, 'listCode in categories page')

    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    //Use anywhere
    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);

    const setCategoriesArray = () => {
        if(categories !== null) return;
        let categoriesArray = [];
        dataProvider.get('product/categories', jwtToken).then((response: any) => {
            if(response !== null) {
                categoriesArray = response.data;
                //console.log(categoriesArray, 'categoriesArray');
                setCategories(categoriesArray);
                //set categories ids dinamically
                categoriesArray.map((category: any) => {
                    //if(category.name_en.toLowerCase() === 'glassware') setCeramicsId(category.id);
                    if(category.name_en.toLowerCase() === 'dinnerware') setDinnerwareId(category.id);
                    if(category.name_en.toLowerCase() === 'glassware') setGlasswareId(category.id);
                    if(category.name_en.toLowerCase() === 'tabletop') setTabletopId(category.id);
                    //if(category.name_en.toLowerCase() === 'table linen') setNapkinsId(category.id);
                    if(category.name_en.toLowerCase() === 'table linen') {setTableLinesId(category.id)}
                });
            }
        });
    }

    useEffect(() => {
        setCategoriesArray();
    }, []);
    //End use anywhere


    //console.log(wishlist, 'wishlist in catalogue page')
    //console.log(wishlist !== null ? wishlist.products : null, 'wishlistProducts in catalogue page')
    //console.log(products, 'products in catalogue page')
    let wishlistUrl = null;
    if(user.role === 1){
        wishlistUrl = `/${pathName}/registry/${listCode}`;
    } else {
        wishlistUrl = `/${pathName}/${listCode}`;
    }
    return (
        <div className="Categories">
            <Header /*hideLanguageSelect={user.role === 2}*/ wishlistUrl={wishlistUrl} link={`/${pathName}/registry/${listCode}`}
                                                             textLink={t('header.home')} hideLogout={true} showWishlistLink={true}/>
            <div className="row no-gutter">
                <div className="CategoriesContainer MultipleCategories col-12 d-md-flex d-none">
                    <div className="CategoriesTitle">
                        <h1>{t('categoriesTitle')}</h1>
                    </div>
                    <div className="TopCategoriesContainer">
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${tableLinesId}`}
                              style={{background: `url(${TableLines}),lightgray 10%`}} className="TopCategory1">
                            <div className="CategoryNameContainer">
                                <span>Table linens</span>
                            </div>
                        </Link>
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${dinnerwareId}`} className="TopCategory2"
                              style={{background: `url(${Dinnerware}),lightgray 50%`}}>
                            <div>
                                <div className="CategoryNameContainer">
                                    <span>Dinnerware</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="BottomCategoryContainer">
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${glasswareId}`}
                              style={{backgroundImage: `url(${Glassware})`, backgroundColor: '#f2f2f4', backgroundSize: 'cover'}} className="BottomCategory1">
                            <div className="CategoryNameContainer">
                                <span>Glassware</span>
                            </div>
                        </Link>
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${tabletopId}`}
                              style={{backgroundImage: `url(${TableTop})`,backgroundColor: '#f2f2f4', backgroundSize: 'cover'}} className="BottomCategory2">
                            <div className="CategoryNameContainer">
                                <span>Tabletop</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="CategoriesContainer MobileCategories MultipleCategories col-12 d-md-none d-flex">
                    <div className="CategoriesTitle">
                        <h1>{t('categoriesTitle')}</h1>
                    </div>
                    <div className="MiddleCategoriesContainer">
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${tableLinesId}`}
                              style={{background: `url(${TableLines}),lightgray 50%`}} className="MiddleCategory1">
                            <div className="CategoryNameContainer">
                                <span>Table Linens</span>
                            </div>
                        </Link>
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${dinnerwareId}`} className="MiddleCategory2"
                              style={{background: `url(${Dinnerware}),lightgray 50%`}}>
                            <div>
                                <div className="CategoryNameContainer">
                                    <span>{t('dinnerware')}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="BottomCategoryContainer">
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${glasswareId}`}
                              style={{backgroundImage: `url(${Glassware})`, backgroundColor: '#f2f2f4', backgroundSize: 'cover'}} className="BottomCategory1">
                            <div className="CategoryNameContainer">
                                <span>Glassware</span>
                            </div>
                        </Link>
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=${tabletopId}`}
                              style={{backgroundImage: `url(${TableTop})`,backgroundColor: '#f2f2f4', backgroundSize: 'cover'}} className="BottomCategory2">
                            <div className="CategoryNameContainer">
                                <span>Tabletop</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <FooterCabana showDisclaimer={false}/>
        </div>
    );

}

export default withTranslation()(Categories);