import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';

import LoginSignup from "./components/auth/LoginSignup";
import GetStarted from "./components/pages/GetStarted";
import ResetPassword from "./components/auth/ResetPassword";
import PageNotFound from "./components/pages/PageNotFound";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";

import Wishlist from "./components/pages/Wishlist";
import EditWishlist from "./components/pages/EditWishlist";
import PrivateRoute from "./components/routing/PrivateRoute";

import {Modal} from "react-bootstrap";
import Switch from '@mui/material/Switch';
import NewPassword from "./components/auth/NewPassword";
import Categories from "./components/pages/Categories";
import Catalogue from "./components/pages/Catalogue";
import Product from "./components/pages/Product";
import CreateMessage from "./components/pages/CreateMessage";
import Message from "./components/pages/Message";
import PurchaseMessage from "./components/pages/PurchaseMessage";
import Checkout from "./components/pages/Checkout";

function App(props: any) {
    const { t, i18n } = useTranslation();

    const [showCookieModal, setShowCookieModal] = React.useState<boolean>(false);

    const handleCloseCookieModal = () => setShowCookieModal(false);
    const handleShowCookieModal = () => setShowCookieModal(true);

    const handleConfirmCookies = () => {
        Cookies.set('cookiesAccepted', 'true');
        handleCloseCookieModal();
    }

    const handleRejectCookies = () => {
        Cookies.set('cookiesAccepted', 'false');
        handleCloseCookieModal();
    }

    /*React.useEffect(() => {
        if (Cookies.get('cookiesAccepted') === undefined && Cookies.get('auth') === 'true') {
            handleShowCookieModal();
        }
    });*/
    const cookieModal = () => {
        return  (<Modal className="ModalCookie" show={showCookieModal} onHide={handleCloseCookieModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modalCookieTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ModalCookieContainer">
                    <div className="EssentialCookiesContainer">
                        <p>{t('modalCookieParagraph')}</p>
                        <Switch defaultChecked disabled={true} />
                    </div>
                    <div className="CookieChoicesContainer">
                        <p>{t('modalCookieChoices')}</p>
                        <Switch defaultChecked disabled={false} />
                    </div>
                    <div className="ConfirmCookies" onClick={() => {handleConfirmCookies()}}>
                        <button className="Fgift-button">{t('confirmCookieChoices')}</button>
                    </div>
                    <div className="CancelCookies" onClick={() => {handleRejectCookies()}}>
                        <button className="Fgift-button" style={{backgroundColor: '#fff', color: '#000'}}>{t('rejectCookieChoices')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
    }

    return (
        <Container className="CabanaContainer" style={{position: 'relative', padding: '0 !important'}} maxWidth={false}>
            <Router>
                <Routes>
                    {/** Gifter routes **/}
                    <Route path={`/gifter/registry/:id`} element={<Wishlist/>}/>
                    <Route path={`/gifter/finalize-gift/:wishlist_product_id`} element={<CreateMessage/>}/>
                    {/** Register routes **/}
                    <Route path='/get-started/:userId' element={<GetStarted />} />
                    <Route path='/' element={<LoginSignup />} />
                    {/** Login **/}
                    <Route path='/login' element={<LoginSignup />} />
                    {/** Reset password **/}
                    <Route path={`/reset-password`} element={<ResetPassword />} />
                    <Route path={`/set-new-password/:token`} element={<NewPassword />} />
                    <Route path="*" element={<PageNotFound />} />
                    <Route path={`/gifter/product`} element={<Product/>}/>
                    <Route path={`/gifter/checkout`} element={<Checkout/>}/>
                    <Route path={`/gifter`} element={<PrivateRoute />}>
                        <Route path={`/gifter/purchase-success`} element={<PurchaseMessage/>}/>
                        <Route path={`/gifter/purchase-error`} element={<PurchaseMessage/>}/>
                    </Route>
                    <Route path={`/giftee`} element={<PrivateRoute />}>
                        <Route path={`/giftee/registry/:id`} element={<Wishlist/>}/>
                        <Route path={`/giftee/registry/:id/edit`} element={<EditWishlist/>}/>
                        <Route path={`/giftee/registry/categories`} element={<Categories/>}/>
                        <Route path={`/giftee/registry/catalogue`} element={<Catalogue/>}/>
                        <Route path={`/giftee/registry/message`} element={<Message/>}/>
                        <Route path={`/giftee/product`} element={<Product/>}/>
                        {/*<Route path={`/giftee/registry/edit/:id`} element={<CreateWishlist/>}/>*/}
                    </Route>
                </Routes>
            </Router>
            {cookieModal()}
        </Container>
    );
}

export default App;