import React from 'react';
import Cookies from "js-cookie";
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';
import {WithTranslation} from "react-i18next";
import {isJwtExpired} from "../../global";

const PrivateRoute = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    let token = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    let isAuthenticated = token !== null && token !== undefined && !isJwtExpired(token);
    if(isJwtExpired(token)) {
        localStorage.clear();
        sessionStorage.clear();
        isAuthenticated = false;
    }

    let loginURL = '/login';
    if(!isAuthenticated) {
        const confirmToken = searchParams.get('confirmToken');
        if(confirmToken) {
            loginURL = `/confirm-registration/${confirmToken}`;
        }

        const redirectURL = window.location.href;
        loginURL += '?redirectURL=' + encodeURIComponent(redirectURL);
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isAuthenticated ? <Outlet /> : <Navigate to={loginURL} />;
}

export default PrivateRoute;